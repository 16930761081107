import { Menu, MenuItem } from '@fountain/fountain-ui-components';
import { show } from '@intercom/messenger-js-sdk';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IntercomMessenger } from 'components/IntercomMessenger';
import ZendeskWidget from 'components/ZendeskWidget';
import {
  makeSelectEmail,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import HelpSvg from 'images/HelpSvg';
import {
  AMAZON_EU_AFP_SLUG,
  AMAZON_EU_DSP_SLUG,
  AMAZON_MEXICO_SLUG,
  AMAZON_NA_AFP_SLUG,
  AMAZON_NA_DSP_SLUG,
} from 'utils/constants';

import messages from './messages';
import useStyles from './styles';

export default function HelpMenu() {
  const classes = useStyles();
  const { accountSlug } = useParams();
  const userEmail = useSelector(makeSelectEmail());
  const { name: userName, feature_flags: featureFlags } = useSelector(
    makeSelectWhoami(),
  );
  const helpCenterHref = getHelpCenterHref({ accountSlug });

  const zendeskSupportChat = !!featureFlags['zendesk-support-chat'];
  const intercomMessenger = featureFlags['intercom-messenger'];

  const [chatIsActive, setChatIsActive] = useState(false);
  const toggleChatWidget = () => {
    if (chatIsActive && intercomMessenger) {
      show();
    }
    setChatIsActive(true);
  };

  return (
    <>
      {chatIsActive &&
        (intercomMessenger ? (
          <IntercomMessenger />
        ) : (
          <ZendeskWidget
            userEmail={userEmail}
            userName={userName}
            zendeskSupportChat={zendeskSupportChat}
          />
        ))}
      <Menu
        id="help-menu"
        className={classes.button}
        MenuListProps={{ className: classes.menuList }}
        triggerEl={<HelpSvg />}
        triggerType="icon"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -5, horizontal: 'left' }}
        closeOnClick
        defaultIconColor
      >
        <MenuItem
          isExternalLink
          target="_blank"
          href={helpCenterHref}
          className={classes.menuListItem}
        >
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage {...messages.helpCenter} />
          </Typography>
        </MenuItem>
        <MenuItem onClick={toggleChatWidget} className={classes.menuListItem}>
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage {...messages.contactSupport} />
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

function getHelpCenterHref({ accountSlug }) {
  switch (accountSlug) {
    case AMAZON_NA_DSP_SLUG:
      return 'https://fountain-help.zendesk.com/hc/en-us/p/DSP_NA';
    case AMAZON_NA_AFP_SLUG:
      return 'https://fountain-help.zendesk.com/hc/en-us/p/AFP_NA';
    case AMAZON_EU_DSP_SLUG:
      return 'https://fountain-help.zendesk.com/hc/en-us/p/DSP_EU';
    case AMAZON_EU_AFP_SLUG:
      return 'https://fountain-help.zendesk.com/hc/en-us/p/AFP_EU';
    case AMAZON_MEXICO_SLUG:
      return 'https://fountain-help.zendesk.com/hc/es/p/DSP_NA';
    default:
      return 'https://support.fountain.com/';
  }
}
