import { CustomerAttributesService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React from 'react';

export function useCustomerAttributeEntities() {
  const fetchCustomerAttributeEntities = React.useCallback(
    () =>
      CustomerAttributesService.getInternalApiCustomerAttributesAttributeEntities(),
    [],
  );

  return useApiService(fetchCustomerAttributeEntities);
}
