import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dataKey: {
    defaultMessage: 'Data key',
    id: `app.containers.WorkflowEditor.components.Rules.Action.IncrementApplicantData.dataKey`,
  },
  changeBy: {
    defaultMessage: 'Change by',
    id: `app.containers.WorkflowEditor.components.Rules.Action.IncrementApplicantData.changeBy`,
  },
});
