import {
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';

import { useStyles } from './styles';

interface RadioButtonsAsCardsProps {
  stage: WorkflowSchedulerStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const RadioButtonsAsCards: FC<RadioButtonsAsCardsProps> = ({
  stage,
  setStage,
}) => {
  const classes = useStyles();

  const setGoEnabled = (value: boolean) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.go_enabled = value;
      }),
    );
  };

  return (
    <div className={classes.optionsContainer}>
      <label
        htmlFor="scheduler_stage_go_not_enabled"
        className={classes.optionDiv}
      >
        <input
          type="radio"
          name="fountain"
          value="hire"
          checked={!stage.go_enabled}
          onChange={() => setGoEnabled(false)}
          className={classes.optionButton}
        />
        <div className={classes.optionContent}>
          <span className={classes.optionTitle}>Fountain Hire users</span>
          <span className={classes.optionDescription}>
            Admins with access to Fountain Hire will serve as interview hosts
          </span>
        </div>
      </label>

      <label htmlFor="scheduler_stage_go_enabled" className={classes.optionDiv}>
        <input
          type="radio"
          name="fountain"
          value="go"
          checked={stage.go_enabled}
          onChange={() => setGoEnabled(true)}
          className={classes.optionButton}
        />
        <div className={classes.optionContent}>
          <span className={classes.optionTitle}>Fountain Hire Go users</span>
          <span className={classes.optionDescription}>
            Frontline managers with Fountain Hire Go accounts will serve as
            interview hosts
          </span>
        </div>
      </label>
    </div>
  );
};
