import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCustomerAttributeValues } from 'hooks/useCustomerAttributeValues';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import messages from './messages';
import { AttributeValueOption } from './types';

export const CustomerAttributeValueSelect: React.VFC<
  SharedConditionProps
> = props => {
  const { condition, ruleId, setRules, setDocumentSigningRules } = props;

  const { data: attributeValues, fetchKey } = useCustomerAttributeValues();
  const [attrTypeId, setAttrTypeId] = useState<number | undefined>(undefined);
  const [options, setOptions] = useState<AttributeValueOption[]>([]);

  const intl = useIntl();

  useEffect(() => {
    if (condition.extra.customer_attributes?.type_id) {
      setAttrTypeId(condition.extra.customer_attributes?.type_id);
    }
  }, [condition]);

  useEffect(() => {
    if (attrTypeId) {
      fetchKey(attrTypeId);
    }
  }, [attrTypeId, fetchKey]);

  useEffect(() => {
    if (attrTypeId) {
      const attrValuesForType = attributeValues[attrTypeId];

      if (attrValuesForType) {
        const mappedOptions = attrValuesForType.map(attr => ({
          label: attr.value,
          value: attr.id,
        }));

        setOptions(mappedOptions);
      }
    }
  }, [attrTypeId, attributeValues]);

  const onChangeCustomerAttributeValue = (attributeValue: {
    label: string;
    value: number;
  }) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          const customerAttributes =
            draftConditions[idx].extra.customer_attributes ?? {};
          customerAttributes.value_id = attributeValue.value;
          draftConditions[idx].extra.customer_attributes = customerAttributes;
        }),
      );
    }
  };

  const selectedAttributeValue = options.find(
    opt => opt.value === condition.extra.customer_attributes?.value_id,
  );

  return (
    <Grid item xs={6}>
      {attrTypeId && (
        <StyledReactSelect
          isLoading={!attributeValues[attrTypeId]}
          isDisabled={!attributeValues[attrTypeId]}
          options={options}
          value={selectedAttributeValue}
          label={intl.formatMessage(messages.value)}
          onChange={onChangeCustomerAttributeValue}
        />
      )}
    </Grid>
  );
};
