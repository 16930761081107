import { Grid } from '@material-ui/core';
import { WorkflowHiredStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import { GoEnabled } from '../../SettingsSwitches';
import {
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
} from './constants';
import messages from './messages';
import { useStyles } from './styles';

export interface AdvancedSettingsCardProps {
  stage: WorkflowHiredStage;
}

export const AdvancedSettingsCard: VFC<AdvancedSettingsCardProps> = ({
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const { setStage } = useContext(StageContext);
  const hasStageLabels = stage.has_stage_labels;

  const onToggleIsHiddenFromPortal = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.is_hidden_from_portal =
          !draftStage.advanced_settings.is_hidden_from_portal;
      }),
    );
  };

  const onToggleShouldAdvanceWhenLabelsComplete = () => {
    setStage(
      produce(stage, draftStage => {
        draftStage.advanced_settings.should_auto_advance_when_labels_complete =
          !draftStage.advanced_settings
            .should_auto_advance_when_labels_complete;
      }),
    );
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.advancedSettings)}
    >
      <Grid className={styles.cardBody}>
        <SettingSwitch
          dataKey={IS_HIDDEN_FROM_PORTAL}
          checked={!stage.advanced_settings.is_hidden_from_portal}
          onChange={onToggleIsHiddenFromPortal}
          label={intl.formatMessage(messages.showStageInApplicantPortal)}
        />
        {hasStageLabels && (
          <SettingSwitch
            dataKey={SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE}
            checked={
              !!stage.advanced_settings.should_auto_advance_when_labels_complete
            }
            onChange={onToggleShouldAdvanceWhenLabelsComplete}
            label={intl.formatMessage(
              messages.shouldAutoAdvanceWhenLabelsComplete,
            )}
          />
        )}
        {stage.account_go_enabled && (
          <GoEnabled stage={stage} setStage={setStage} />
        )}
      </Grid>
    </StageSettingCard>
  );
};
