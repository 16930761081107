/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Count } from '../models/Count';
import type { Event } from '../models/Event';
import type { EventAvailabilityNew } from '../models/EventAvailabilityNew';
import type { EventAvailableSlot } from '../models/EventAvailableSlot';
import type { EventFormResult } from '../models/EventFormResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * Get bookings count for a series of events
     * @param seriesKey Series key
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiEventsBookings(
        seriesKey: string,
    ): CancelablePromise<{
        /**
         * Number of unbooked slots
         */
        unbooked_slots: number;
        /**
         * Number of booked slots
         */
        booked_slots: number;
        /**
         * Number of booked applicants
         */
        booked_applicants: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/bookings/{series_key}',
            path: {
                'series_key': seriesKey,
            },
        });
    }

    /**
     * Options and defaults for availability by applicant
     * users and on site addresses
     * @param applicantId
     * @param stageId
     * @returns EventAvailabilityNew successful
     * @throws ApiError
     */
    public static getInternalApiEventsAvailabilityNew(
        applicantId: string,
        stageId: string,
    ): CancelablePromise<EventAvailabilityNew> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/availability/new',
            query: {
                'applicant_id': applicantId,
                'stage_id': stageId,
            },
        });
    }

    /**
     * Requests Event Onsite Adresses
     * Returns event stage titles
     * @param stageTitles
     * @param openingTitles
     * @param locationGroupTitles
     * @param hostId
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiEventsConditions(
        stageTitles?: Array<string>,
        openingTitles?: Array<string>,
        locationGroupTitles?: Array<string>,
        hostId?: string,
    ): CancelablePromise<{
        onsite_addresses: Array<{
            onsite_address: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/conditions',
            query: {
                'stage_titles[]': stageTitles,
                'opening_titles[]': openingTitles,
                'location_group_titles[]': locationGroupTitles,
                'host_id': hostId,
            },
        });
    }

    /**
     * Creates export request
     * Sends current user an export using filters
     * @param requestBody
     * @returns any successful
     * @throws ApiError
     */
    public static postInternalApiEventsExports(
        requestBody: {
            stage_title: Array<string>;
            funnel_ids: Array<string>;
            date: 'today' | 'upcoming' | 'past';
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/events/exports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Requests Event Stage Options
     * Returns event stages within the account to assign events to
     * @param stageTitle Orientation Day
     * @param funnelIds abc-124
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiEventsStageOptions(
        stageTitle?: Array<string>,
        funnelIds?: Array<string>,
    ): CancelablePromise<Array<{
        external_id: string;
        funnel_external_id: string;
        title: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/stage_options',
            query: {
                'stage_title[]': stageTitle,
                'funnel_ids[]': funnelIds,
            },
        });
    }

    /**
     * Requests Event Stage Options
     * Returns event stage titles
     * @param query Orie
     * @param hostId User to scope by (default: current user)
     * @param stageTitle
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiEventsStageTitles(
        query?: string,
        hostId?: string,
        stageTitle?: Array<string>,
    ): CancelablePromise<{
        titles: Array<string>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/stage_titles',
            query: {
                'query': query,
                'host_id': hostId,
                'stage_title[]': stageTitle,
            },
        });
    }

    /**
     * Requests Events
     * Returns events for the logged in user
     * @param date filter query for past or future events
     * @param page 1
     * @param stageTitle Orientation Day
     * @param funnelIds abc-124
     * @returns Event successful
     * @throws ApiError
     */
    public static getInternalApiEvents(
        date?: 'today' | 'upcoming' | 'past',
        page?: number,
        stageTitle?: Array<string>,
        funnelIds?: Array<string>,
    ): CancelablePromise<Array<Event>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events',
            query: {
                'date': date,
                'page': page,
                'stage_title[]': stageTitle,
                'funnel_ids[]': funnelIds,
            },
        });
    }

    /**
     * create event
     * @param requestBody
     * @param date filter query for past or future events
     * @param page 1
     * @param stageTitle Orientation Day
     * @param funnelIds abc-124
     * @returns EventFormResult successful
     * @throws ApiError
     */
    public static postInternalApiEvents(
        requestBody: {
            event: EventAvailableSlot;
        },
        date?: 'today' | 'upcoming' | 'past',
        page?: number,
        stageTitle?: Array<string>,
        funnelIds?: Array<string>,
    ): CancelablePromise<EventFormResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/events',
            query: {
                'date': date,
                'page': page,
                'stage_title[]': stageTitle,
                'funnel_ids[]': funnelIds,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Destroy Event
     * Destroys event
     * @param externalId Event External ID
     * @param requestBody
     * @returns Count successful
     * @throws ApiError
     */
    public static deleteInternalApiEvents(
        externalId: string,
        requestBody: {
            /**
             * How to delete a series (default: Single)
             */
            delete_mode?: 'single' | 'unbooked' | 'all';
        },
    ): CancelablePromise<Count> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal_api/events/{external_id}',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `unauthorized`,
            },
        });
    }

    /**
     * Get Event
     * @param externalId Event External ID
     * @returns EventAvailableSlot successful
     * @throws ApiError
     */
    public static getInternalApiEvents1(
        externalId: string,
    ): CancelablePromise<EventAvailableSlot> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/events/{external_id}',
            path: {
                'external_id': externalId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Update Event
     * @param externalId Event External ID
     * @param requestBody
     * @returns EventFormResult successful
     * @throws ApiError
     */
    public static putInternalApiEvents(
        externalId: string,
        requestBody: {
            user_external_id: string;
            /**
             * How to update a series (default: Single)
             */
            update_mode?: 'single' | 'unbooked' | 'all';
            event: EventAvailableSlot;
        },
    ): CancelablePromise<EventFormResult> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/events/{external_id}',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
