import { ApiSelfServeV2Service } from 'api-clients/monolith';
import { SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Filterable } from '../../util';

export const useLocationGroups = (
  enterprise: boolean | undefined,
  userId?: string,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [query, setQuery] = useState('');
  const [locationGroups, setLocationGroups] = useState<Filterable[]>([]);

  useEffect(() => {
    const fetchLocationGroups = async () => {
      const stageOptions =
        await ApiSelfServeV2Service.getApiSelfServeV2LocationGroups(
          query,
          userId,
        );
      const filterableLocationGroups = stageOptions.location_groups?.map(
        locationGroup => ({
          title: locationGroup.name,
          id: locationGroup.id,
        }),
      );
      setLocationGroups(
        filterableLocationGroups as SetStateAction<Filterable[]>,
      );
    };

    void fetchLocationGroups();
  }, [query, enterprise, userId, dispatch, intl]);

  return { locationGroups, setQuery };
};
