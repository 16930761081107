import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  customerAttributeType: {
    defaultMessage: 'Customer Attribute Type',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.components.CustomerAttributeSelect.customerAttributeType`,
  },
  search: {
    defaultMessage: 'Search...',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.components.CustomerAttributeSelect.search`,
  },
  value: {
    defaultMessage: 'Value',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.components.CustomerAttributeSelect.value`,
  },
});
export default messages;
