import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import { useEventsBookings } from 'Calendar/hooks';
import React, { useEffect, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { UpdateModeType, UpdateModeTypes } from '../types';
import { messages } from './messages';
import { useStyles } from './styles';

export interface UpdateModeConfirmationModalProps {
  event: EventAvailableSlot;
  open: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  onClose: () => void;
  onConfirm: (selectedValue: UpdateModeType) => void;
}

export const UpdateModeConfirmationModal: VFC<
  UpdateModeConfirmationModalProps
> = ({ event, open, isLoading, isDeleting, onClose, onConfirm }) => {
  const styles = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const isBookedSession =
    event.booked_slots_count && event.booked_slots_count > 0;

  const [selectedOption, setSelectedOption] = React.useState<UpdateModeType>(
    isBookedSession ? UpdateModeTypes.ALL : UpdateModeTypes.UNBOOKED,
  );

  const { result } = useEventsBookings({
    seriesKey: event.series_key as string,
  });

  useEffect(() => {
    if (result.status === 'ready' && result.isError) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.fetchBookingsError),
          'error',
        ),
      );
    }
  }, [result, dispatch, intl]);

  let bodyContent;

  if (result.status !== 'ready') {
    bodyContent = <CircularProgress />;
  } else {
    const seriesBookings = result.data;

    const allSessionsAffectedMessage = (
      <>
        {isDeleting && (
          <Typography variant="body1" className={styles.preWrapText}>
            <FormattedMessage {...messages.confirmDelete} />
          </Typography>
        )}
        <Typography className={styles.preWrapText}>
          <FormattedMessage
            {...messages.allSessionsAffected}
            values={{
              bookedSessions: seriesBookings.booked_slots,
              totalSessions:
                seriesBookings.unbooked_slots + seriesBookings.booked_slots,
              negative: isDeleting,
            }}
          />
        </Typography>
      </>
    );

    const selectOptionsBodyContent = (
      <RadioGroup
        value={selectedOption}
        onChange={e => setSelectedOption(e.target.value as UpdateModeType)}
        className={styles.radioGroup}
      >
        <FormControlLabel
          value={UpdateModeTypes.UNBOOKED}
          control={<Radio color="primary" />}
          label={
            <Typography variant="body1" className={styles.preWrapText}>
              <FormattedMessage
                {...messages.onlyFutureUnbookedSessionsAffected}
              />
            </Typography>
          }
        />
        <FormControlLabel
          value={UpdateModeTypes.ALL}
          control={<Radio color="primary" />}
          label={
            <Typography variant="body1" className={styles.preWrapText}>
              <FormattedMessage
                {...messages.allFutureSessionsIncludingBookedAffected}
                values={{
                  bookedSessions: seriesBookings.booked_slots,
                  totalApplicants: seriesBookings.booked_applicants,
                }}
              />
            </Typography>
          }
        />
      </RadioGroup>
    );

    if (isBookedSession || seriesBookings.booked_slots === 0) {
      bodyContent = allSessionsAffectedMessage;
    } else {
      bodyContent = selectOptionsBodyContent;
    }
  }

  return (
    <ConfirmationModal
      ariaLabelledBy="update-mode-confirmation-modal"
      bodyContent={bodyContent}
      cancelButtonContent="Cancel"
      confirmButtonContent={
        <FormattedMessage
          {...(isDeleting
            ? messages.confirmDeleteButton
            : messages.confirmEditButton)}
        />
      }
      isOpen={open}
      isLoading={isLoading}
      disabled={false}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={() => onConfirm(selectedOption)}
      title={intl.formatMessage(
        isDeleting ? messages.deleteTitle : messages.editTitle,
      )}
      negative={isDeleting}
      maxWidth={482}
    />
  );
};
