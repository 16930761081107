import { CustomerAttributesService } from 'api-clients/monolith';

import { useAggregatorApiService } from './useAggregatorApiService';

export const useCustomerAttributeValues = () => {
  const service = useAggregatorApiService(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    CustomerAttributesService.getInternalApiCustomerAttributesAttributeValues,
  );

  return { ...service };
};
