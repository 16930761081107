import { Loader, StyledReactSelect } from '@fountain/fountain-ui-components';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChatbotWidgetBrand } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  makeIsCandidateAiApplyV2Enabled,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';

import { updateAgentConnection, updateAxAgent } from '../api';
import {
  ALL_BRANDS,
  applyBots,
  applyV2Bots,
  INDIVIDUAL_BRAND,
} from '../constants';
import { messages } from '../messages';
import { ApplyBotTypes, AxAgentValues, SelectedBrand } from '../types';
import { Footer } from './Footer';
import { useChooseBrandStyles } from './styles';
import useValidation from './useValidation';
import { Widget } from './Widget';

export interface ChooseBrandProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  axAgentValues: AxAgentValues;
  accessToken: string;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  resetAgent: () => void;
  accountSlug: string;
}

export const ChooseBrand: VFC<ChooseBrandProps> = ({
  currentStep,
  setCurrentStep,
  axAgentValues,
  accessToken,
  setAxAgentValues,
  setStartChatbotProgressor,
  setHasUnsavedChanges,
  setIsLoading,
  isLoading,
  resetAgent,
  accountSlug,
}) => {
  const intl = useIntl();
  const classes = useChooseBrandStyles();
  const {
    brands,
    brandId,
    totalChatAgents,
    axAgentId,
    noOfActiveFunnelsVisibleToApplicant,
    isDuplicateSettingCannotApplyAgain,
  } = axAgentValues;

  const { default_brand_id: defaultBrandId } = useSelector(makeSelectWhoami());
  const isAllBrandSelected = brandId === null;
  const [brandOption, setBrandOption] = useState(
    isAllBrandSelected ? ALL_BRANDS : INDIVIDUAL_BRAND,
  );
  const [applyBotType, setApplyBotType] = useState(axAgentValues.applyBotType);
  const isAllBrandDisabled = totalChatAgents > 1;
  const defaultBrand = brands.find(item => item.key === defaultBrandId);
  const [selectedBrand, setSelectedBrand] = useState<SelectedBrand>({
    option: {} as ChatbotWidgetBrand,
    brandId,
  });
  const { validateFields } = useValidation();

  const isCandidateAiApplyV2Enabled = useSelector(
    makeIsCandidateAiApplyV2Enabled(),
  );
  const botTypeOptions = isCandidateAiApplyV2Enabled ? applyV2Bots : applyBots;

  useEffect(() => {
    setBrandOption(isAllBrandSelected ? ALL_BRANDS : INDIVIDUAL_BRAND);
  }, [isAllBrandSelected]);

  useEffect(() => {
    if (brands.length > 0) {
      const initialBrand = isDuplicateSettingCannotApplyAgain
        ? defaultBrand
        : brands.find(item => item.key === brandId);
      const initialBrandId = isAllBrandSelected
        ? null
        : initialBrand?.key ?? brands[0].key;

      setSelectedBrand({
        option: initialBrand ?? brands[0],
        brandId: initialBrandId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  useEffect(() => {
    if (Object.keys(selectedBrand.option).length > 0) {
      setAxAgentValues(prev => ({
        ...prev,
        brandName: selectedBrand.option.label,
        brandId: selectedBrand.brandId as string,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const updateAgentsBrandDetails = async (option: {
    key?: string;
    label: string;
  }) => {
    setIsLoading(true);
    await updateAxAgent(accessToken, axAgentId, {
      brand: option.label,
    });
    const agentConnectionResponse = await updateAgentConnection(
      accountSlug,
      axAgentId,
      option.key,
    );

    setAxAgentValues(prev => ({
      ...prev,
      brandId: agentConnectionResponse.agent_connection.brand_external_id,
      brands: agentConnectionResponse.agent_connection.available_brands,
      noOfActiveFunnelsVisibleToApplicant:
        agentConnectionResponse.agent_connection
          .no_of_active_funnels_visible_to_applicant,
    }));
    setIsLoading(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrandOption(event.target.value);
    if (event.target.value === ALL_BRANDS) {
      void updateAgentsBrandDetails({
        label: defaultBrand?.label as string,
      });

      setSelectedBrand({
        option: defaultBrand as ChatbotWidgetBrand,
        brandId: null,
      });
    } else {
      void updateAgentsBrandDetails(selectedBrand.option);
      setSelectedBrand({
        option: selectedBrand.option,
        brandId: selectedBrand.option.key,
      });
    }
    setHasUnsavedChanges(true);
  };

  const handleBrandChange = (option: ChatbotWidgetBrand) => {
    const brandId = option.key;
    void updateAgentsBrandDetails(option);

    setSelectedBrand({ option, brandId } as SelectedBrand);
    setHasUnsavedChanges(true);
  };

  const handleApplyBotChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const selectedKey = event.target.value;

    setApplyBotType(selectedKey as ApplyBotTypes);
    setHasUnsavedChanges(true);
    setAxAgentValues(prev => ({
      ...prev,
      applyBotType: selectedKey as ApplyBotTypes,
    }));
  };

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Grid className={classes.container}>
      <Grid className={classes.contentContainer}>
        <Grid className={classes.header}>
          <Typography variant="h2">
            <FormattedMessage {...messages.customizeWidgetAppearance} />
          </Typography>
        </Grid>
        <Grid className={classes.content}>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Grid className={classes.sectionHeader}>
              <Typography variant="h3">
                <FormattedMessage {...messages.chooseBrand} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage {...messages.chooseBrandDescription} />
              </Typography>
            </Grid>
            <RadioGroup
              name="choose_brand"
              value={brandOption}
              onChange={handleRadioChange}
              style={{ gap: '16px' }}
            >
              <Tooltip
                title={
                  isAllBrandDisabled
                    ? intl.formatMessage(messages.allBrandDisableMessage)
                    : ''
                }
                disableHoverListener={!isAllBrandDisabled}
                placement="top"
              >
                <Box>
                  <FormControlLabel
                    value={ALL_BRANDS}
                    disabled={isAllBrandDisabled}
                    control={
                      <Radio color="primary" style={{ paddingTop: '4px' }} />
                    }
                    label={
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          variant="body1"
                          display="inline"
                          className={
                            isAllBrandDisabled
                              ? classes.disabled
                              : classes.label
                          }
                        >
                          All brands
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          display="block"
                          className={
                            isAllBrandDisabled
                              ? classes.disabled
                              : classes.label
                          }
                        >
                          <FormattedMessage {...messages.allBrandDescription} />
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={
                  isDuplicateSettingCannotApplyAgain
                    ? intl.formatMessage(
                        messages.duplicateSettingCannotApplyAgain,
                      )
                    : ''
                }
                disableHoverListener={!isDuplicateSettingCannotApplyAgain}
                placement="top"
              >
                <Grid>
                  <FormControlLabel
                    value={INDIVIDUAL_BRAND}
                    control={
                      <Radio color="primary" style={{ paddingTop: '0px' }} />
                    }
                    disabled={isDuplicateSettingCannotApplyAgain}
                    label={
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          variant="body1"
                          display="inline"
                          className={
                            isDuplicateSettingCannotApplyAgain
                              ? classes.disabled
                              : classes.label
                          }
                        >
                          Individual brand
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          display="block"
                          className={
                            isDuplicateSettingCannotApplyAgain
                              ? classes.disabled
                              : classes.label
                          }
                        >
                          <FormattedMessage
                            {...messages.individualBrandDescription}
                          />
                        </Typography>
                      </Box>
                    }
                  />
                  {brandOption === INDIVIDUAL_BRAND && (
                    <>
                      <StyledReactSelect
                        options={brands}
                        value={selectedBrand.option}
                        onChange={handleBrandChange}
                        placeholder={intl.formatMessage(
                          messages.selectBrandPlaceholder,
                        )}
                        label={intl.formatMessage(messages.brand)}
                        getOptionLabel={(brand: ChatbotWidgetBrand) =>
                          brand.label
                        }
                        getOptionValue={(brand: ChatbotWidgetBrand) =>
                          brand.key
                        }
                        isDisabled={isDuplicateSettingCannotApplyAgain}
                        className={classes.chooseBrandInput}
                      />
                    </>
                  )}
                </Grid>
              </Tooltip>
            </RadioGroup>
          </Box>
        </Grid>
        <Grid className={classes.content}>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Grid className={classes.sectionHeader}>
              <Typography variant="h3">
                <FormattedMessage {...messages.agentFunctionTitle} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage {...messages.agentFunctionDesc} />
              </Typography>
            </Grid>
            <RadioGroup
              name="apply_bot_type"
              value={applyBotType}
              onChange={handleApplyBotChange}
              style={{ gap: '16px' }}
            >
              <Box
                className={
                  noOfActiveFunnelsVisibleToApplicant === 0
                    ? classes.disabled
                    : ''
                }
              >
                <FormControlLabel
                  value="apply"
                  control={
                    <Radio
                      color="primary"
                      checked={botTypeOptions
                        .map(bot => bot.key)
                        .includes(applyBotType)}
                      disabled={noOfActiveFunnelsVisibleToApplicant === 0}
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="body1"
                        display="inline"
                        className={classes.label}
                      >
                        <FormattedMessage {...messages.applyBotTitle} />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="block"
                      >
                        <FormattedMessage {...messages.applyBotDesc} />
                      </Typography>
                    </Box>
                  }
                />

                <FormControl fullWidth className={classes.chooseApplyBot}>
                  <RadioGroup
                    name="job_display_type"
                    value={applyBotType}
                    onChange={handleApplyBotChange}
                    style={{ gap: '16px' }}
                  >
                    {botTypeOptions.map(option => {
                      const isOnlyOneJob =
                        noOfActiveFunnelsVisibleToApplicant === 1;
                      const isMoreThanOneJob =
                        noOfActiveFunnelsVisibleToApplicant > 1;

                      // Determine if all options should be disabled
                      const isAllDisabled =
                        noOfActiveFunnelsVisibleToApplicant === 0;
                      // Determine if option should be disabled
                      const isDisabled =
                        isAllDisabled ||
                        (option.key === 'apply_one' && isMoreThanOneJob) ||
                        (option.key !== 'apply_one' && isOnlyOneJob);

                      // Define the tooltip message for disabled options
                      let tooltipMessage = '';
                      if (isDisabled) {
                        tooltipMessage = isAllDisabled
                          ? intl.formatMessage(messages.noJobAvailableTooltip)
                          : option.key === 'apply_one'
                          ? intl.formatMessage(
                              messages.moreThanOneJobAvailableTooltip,
                            )
                          : intl.formatMessage(messages.oneJobAvailableTooltip);
                      }

                      return (
                        <>
                          <Tooltip
                            title={isDisabled ? tooltipMessage : ''}
                            arrow
                          >
                            <span>
                              <FormControlLabel
                                key={option.key}
                                value={option.key}
                                control={
                                  <Radio
                                    color="primary"
                                    disabled={isDisabled}
                                    checked={option.key === applyBotType}
                                  />
                                }
                                label={
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    className={
                                      isDisabled ? classes.disabled : ''
                                    }
                                  >
                                    <Typography
                                      variant="body1"
                                      display="inline"
                                      className={classes.label}
                                    >
                                      {option.label}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      display="block"
                                    >
                                      {option.description}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </span>
                          </Tooltip>
                        </>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Box>

              <Grid>
                <FormControlLabel
                  value="no_apply"
                  control={
                    <Radio
                      color="primary"
                      checked={applyBotType === 'no_apply'}
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="body1"
                        display="inline"
                        className={classes.label}
                      >
                        <FormattedMessage {...messages.applyFaqBotTitle} />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="block"
                      >
                        <FormattedMessage {...messages.applyFaqBotDesc} />
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
            </RadioGroup>
          </Box>
        </Grid>
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          axAgentValues={axAgentValues}
          accessToken={accessToken}
          setStartChatbotProgressor={setStartChatbotProgressor}
          setAxAgentValues={setAxAgentValues}
          validateFields={() => validateFields(axAgentValues, currentStep)}
          resetAgent={resetAgent}
          accountSlug={accountSlug}
        />{' '}
      </Grid>
      <Grid className={classes.widgetContainer}>
        <Widget axAgentId={axAgentId} selectedBrand={selectedBrand} />
      </Grid>
    </Grid>
  );
};
