import {
  Datepicker,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { FormatDateOptions, FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import useTranslatedConstants from 'containers/SendEmailPopup/hooks';
import globalMessages from 'shared/global/messages';
import { generateAvailabilityTimes, minDate, TimeZoneParam } from 'utils/date';

import messages from './messages';
import useStyles from './styles';

type LabelValue = {
  label: string;
  value: string;
};

interface HandleChangeData extends FormData {
  sendAt: LabelValue;
  sendType: LabelValue;
  startDate: Date;
}

export interface DelayMessageOptionsProps {
  handleChange: (params: Partial<HandleChangeData>) => void;
  sendType: LabelValue;
  startDate: Date;
  timeZone: TimeZoneParam;
  sendAt: LabelValue;
}

export const DelayMessageOptions: FC<DelayMessageOptionsProps> = ({
  handleChange,
  sendType,
  startDate,
  timeZone,
  sendAt,
}) => {
  const classes = useStyles();
  const locale = useSelector(makeSelectLocale());
  const { SEND_TYPE, SEND_TYPE_OPTIONS } = useTranslatedConstants();
  const [availableTimes, setAvailableTimes] = useState<LabelValue[]>(
    generateAvailabilityTimes(new Date(), 15, timeZone),
  );
  const formatDateOptions: FormatDateOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    ...timeZone,
  };

  const handleChangeSendType = (type: LabelValue) => {
    handleChange({
      sendType: type,
      sendAt,
    });
  };

  const handleChangeDate = (date: Date | Date[] | undefined) => {
    if (!(date instanceof Date)) return;
    const currentAvailableTimes = generateAvailabilityTimes(date, 15, timeZone);

    setAvailableTimes(currentAvailableTimes);
    handleChange({ startDate: date });
  };

  useEffect(() => {
    handleChange({ sendAt: availableTimes[0] });
  }, [availableTimes, handleChange]);

  useEffect(() => {
    if (sendType.value === SEND_TYPE.NOW) {
      handleChange({ sendAt: undefined });
    }
  }, [SEND_TYPE.NOW, handleChange, sendAt, sendType]);

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledReactSelect
            label={<FormattedMessage {...globalMessages.when} />}
            options={SEND_TYPE_OPTIONS}
            value={sendType}
            onChange={(event: LabelValue) => handleChangeSendType(event)}
          />
        </Grid>
        {sendType.value === SEND_TYPE.LATER && (
          <>
            <Grid item data-testid="send-on-date-picker" xs={12} md={4}>
              <Typography variant="body2" className={classes.label}>
                <FormattedMessage {...globalMessages.on} />
              </Typography>
              <Datepicker
                locale={locale}
                minDate={minDate(timeZone)}
                value={startDate}
                // Without this coersion, Typescript complains because it expects OnChangeDateCallback to handle both Date and Array<Date>
                // However, we'll never pass an Array<Date> here, so there's no reason to change the handleChangeDate function
                onDateChange={handleChangeDate}
                renderDate={selectedDate => (
                  <FormattedDate dateStyle="short" value={selectedDate} />
                )}
              />
            </Grid>
            <Grid item data-testid="send-at-time-selector" xs={12} md={4}>
              <StyledReactSelect
                label={<FormattedMessage {...globalMessages.at} />}
                options={availableTimes}
                value={sendAt}
                getOptionLabel={({
                  value,
                }: {
                  value: string | number | Date | undefined;
                }) => <FormattedDate value={value} {...formatDateOptions} />}
                onChange={(event: LabelValue) =>
                  handleChange({ sendAt: event })
                }
              />
            </Grid>
            <div className={classes.messageTimeZoneInfo}>
              <FormattedMessage
                {...messages.messageTimeZoneInfo}
                values={{
                  timezone: timeZone.timeZone,
                }}
              />
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};
