import { makeStyles } from '@material-ui/core/styles';

const smText = {
  fontSize: '12px',
  fontWeight: 450,
};

const baseTextDark = {
  color: '#131E40',
};

const flex = {
  display: 'flex',
};

export const useFormBuilderStyles = makeStyles(theme => ({
  drawer: {
    width: '640px',
  },
  drawerHeader: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    ...baseTextDark,
    fontSize: '18px',
    fontWeight: 500,
  },
  questionsContainer: {
    background: theme.palette.common.gray100,
    borderRadius: theme.misc.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    listStyleType: 'none',
  },
  addQuesitonButtonText: {
    ...flex,
    gap: `calc(${theme.spacing(1)}px / 2)`,
    alignItems: 'center',
  },
  addIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  emptyStateContainer: {
    ...flex,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.common.gray100,
    borderRadius: theme.misc.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const useQuestionnaireDisplayStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      2,
    )}px ${theme.spacing(2)}px`,
    background: theme.palette.common.white,
    borderRadius: theme.misc.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  keyChip: {
    ...smText,
    borderRadius: `calc(${theme.spacing(1)}px / 2)`,
  },
  type: {
    ...smText,
  },
  questionContainer: {
    ...flex,
    flexDirection: 'column',
    gap: `calc(${theme.spacing(1)}px / 2)`,
  },
  metaContainer: {
    ...flex,
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  vpnIcon: {
    width: '12px',
    height: 'auto',
  },
  actionsContainer: {
    ...flex,
    gap: `calc(${theme.spacing(1)}px / 2)`,
    marginLeft: 'auto',
    alignItems: 'center',
  },
  actionsIcons: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  'MuiChip-root': {
    backgroundColor: theme.palette.background.default,
  },
}));
