import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.containers.ChatAgent.title',
    defaultMessage: 'Fountain AI',
  },
  header: {
    id: 'app.containers.ChatAgent.header',
    defaultMessage: 'Candidate AI Agent',
  },
  aiAgentSetup: {
    id: 'app.containers.ChatAgent.aiAgentSetup',
    defaultMessage: 'AI Agent setup',
  },
  aiRecommendations: {
    id: 'app.containers.ChatAgent.aiRecommendations',
    defaultMessage: 'AI recommendations',
  },
  setupFirstWidget: {
    id: 'app.containers.ChatAgent.setupFirstWidget',
    defaultMessage: 'Set up your first chatbot',
  },
  setupDescription: {
    id: 'app.containers.ChatAgent.setupDescription',
    defaultMessage:
      'Your Fountain AI chatbot is almost ready to go. Set up your chatbot to support applicants with their application questions and process at any time of the day!',
  },
  setupAiAgent: {
    id: 'app.containers.ChatAgent.setupAiAgent',
    defaultMessage: 'Set up AI Agent',
  },
  uploadKnowledgeBase: {
    id: 'app.containers.ChatAgent.uploadKnowledgeBase',
    defaultMessage: 'Upload knowledge base',
  },
  uploadKnowledgeBaseInstruction: {
    id: 'app.containers.ChatAgent.uploadKnowledgeBaseInstruction',
    defaultMessage:
      'Upload a .csv file to train the chatbot to answer questions that are not found from the existing Fountain data (ie. Job Description, Position, Location, Opening Details, and Workflow).',
  },
  sampleKnowledgeBaseInstruction: {
    id: 'app.containers.ChatAgent.sampleKnowledgeBaseInstruction',
    defaultMessage: `Use this <templateFileLink>template file</templateFileLink> to upload a .csv file of the knowledge base in the format of question and answer. For best practices on creating your knowledge base, please refer to the <helpCenterLink>Help Center</helpCenterLink> article.`,
  },
  customizeAiAgent: {
    id: 'app.containers.ChatAgent.customizeAiAgent',
    defaultMessage: 'Customize AI Agent',
  },
  aiAgentVisibility: {
    id: 'app.containers.ChatAgent.aiAgentVisibility',
    defaultMessage: 'AI Agent visibility',
  },
  aiAgentVisibilityInstruction: {
    id: 'app.containers.ChatAgent.aiAgentVisibilityInstruction',
    defaultMessage:
      'Select which platforms to enable the AI Agent to assist applicants.',
  },
  fallbackInteractions: {
    id: 'app.containers.ChatAgent.fallbackInteractions',
    defaultMessage: 'Fallback interactions',
  },
  fallbackInteractionsInstruction: {
    id: 'app.containers.ChatAgent.fallbackInteractionsInstruction',
    defaultMessage:
      'Customize a fallback interaction method to sustain a conversation when the AI Agent can’t respond to an applicant.',
  },
  contactInfo: {
    id: 'app.containers.ChatAgent.contactInfo',
    defaultMessage: 'Contact info',
  },
  contactInfoPlaceholder: {
    id: 'app.containers.ChatAgent.contactInfoPlaceholder',
    defaultMessage: 'Enter phone number, email or website',
  },
  customizeWidgetAppearance: {
    id: 'app.containers.ChatAgent.customizeWidgetAppearance',
    defaultMessage: 'Customize widget appearance',
  },
  chooseBrand: {
    id: 'app.containers.ChatAgent.chooseBrand',
    defaultMessage: 'Choose brand',
  },
  chooseBrandDescription: {
    id: 'app.containers.ChatAgent.chooseBrandDescription',
    defaultMessage:
      "Choose a brand to apply it's logo and color to the appearance of the widget",
  },
  selectBrandPlaceholder: {
    id: 'app.containers.ChatbotWidget.selectBrandPlaceholder',
    defaultMessage: 'Please select Brand',
  },
  brand: {
    id: 'app.containers.ChatAgent.brand',
    defaultMessage: 'Brand',
  },
  aiAgentName: {
    id: 'app.containers.ChatAgent.aiAgentName',
    defaultMessage: 'AI Agent name',
  },
  aiAgentNameRequired: {
    id: 'app.containers.ChatAgent.aiAgentNameRequired',
    defaultMessage: 'AI Agent name is required.',
  },
  visibilityIsRequired: {
    id: 'app.containers.ChatAgent.visibilityIsRequired',
    defaultMessage: 'At least one visibility option must be selected.',
  },
  chatbotName: {
    id: 'app.containers.ChatAgent.chatbotName',
    defaultMessage: 'Chatbot name',
  },
  chatbotNamePlaceholder: {
    id: 'app.containers.ChatAgent.chatbotNamePlaceholder',
    defaultMessage: 'Ex. Ava, Luna, Maya, Oliver, Milo',
  },
  agentPublishSuccess: {
    id: 'app.containers.ChatAgent.agentPublishSuccess',
    defaultMessage: 'Agent published successfully',
  },
  agentPublishFailed: {
    id: 'app.containers.ChatAgent.agentPublishFailed',
    defaultMessage: 'Failed to publish Agent',
  },
  agentSaveSuccess: {
    id: 'app.containers.ChatAgent.agentSaveSuccess',
    defaultMessage: 'Agent saved successfully',
  },
  agentSaveFailed: {
    id: 'app.containers.ChatAgent.agentSaveFailed',
    defaultMessage: 'Failed to save Agent',
  },
  fetchAccessTokenFailed: {
    id: 'app.containers.ChatAgent.fetchAccessTokenFailed',
    defaultMessage: 'Failed to fetch Access Token',
  },
  uploadFileSuccess: {
    id: 'app.containers.ChatAgent.uploadFileSuccess',
    defaultMessage: 'File uploaded successfully',
  },
  uploadFileFailed: {
    id: 'app.containers.ChatAgent.uploadFileFailed',
    defaultMessage: 'Failed to upload file',
  },
  downloadFileSuccess: {
    id: 'app.containers.ChatAgent.downloadFileSuccess',
    defaultMessage: 'File downloaded successfully',
  },
  downloadFileFailed: {
    id: 'app.containers.ChatAgent.downloadFileFailed',
    defaultMessage: 'Failed to download file',
  },
  fetchKnowledgeBaseFailed: {
    id: 'app.containers.ChatAgent.fetchKnowledgeBaseFailed',
    defaultMessage: 'Failed to fetch knowledge base',
  },
  fetchAgentsFailed: {
    id: 'app.containers.ChatAgent.fetchAgentsFailed',
    defaultMessage: 'Failed to fetch agents',
  },
  candidateAiAgent: {
    id: 'app.containers.ChatAgent.candidateAiAgent',
    defaultMessage: 'Candidate AI Agent',
  },
  agentCreationFailed: {
    id: 'app.containers.ChatAgent.agentCreationFailed',
    defaultMessage: 'Failer to create Agent',
  },
  email: {
    id: 'app.containers.ChatAgent.email',
    defaultMessage: 'Email',
  },
  phoneNumber: {
    id: 'app.containers.ChatAgent.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  website: {
    id: 'app.containers.ChatAgent.website',
    defaultMessage: 'Website',
  },
  fallbackMethod: {
    id: 'app.containers.ChatAgent.fallbackMethod',
    defaultMessage: 'Fallback method',
  },
  copiedToClipboard: {
    id: 'app.containers.ChatAgent.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
  },
  failedToCopy: {
    id: 'app.containers.ChatAgent.failedToCopy',
    defaultMessage: 'Failed to copy',
  },
  agentDeleteSuccess: {
    id: 'app.containers.ChatAgent.agentDeleteSuccess',
    defaultMessage: 'Agent deleted successfully',
  },
  agentDeleteFailed: {
    id: 'app.containers.ChatAgent.agentDeleteFailed',
    defaultMessage: 'Failed to delete agent',
  },
  noBrandAvailableForAgent: {
    id: 'app.containers.ChatAgent.noBrandAvailableForAgent',
    defaultMessage: 'No more brand is available to set up AI Agent',
  },
  allBrandAvailableForAgent: {
    id: 'app.containers.ChatAgent.allBrandAvailableForAgent',
    defaultMessage:
      'An AI Agent for "All Brand" exist. To add multiple AI agents, update the existing AI agent’s brand selection to “Individual brand".',
  },
  fountainWebsiteDescription: {
    id: 'app.containers.ChatAgent.fountainWebsiteDescription',
    defaultMessage:
      'AI Agent will be enabled across the Job Directory and Applicant Portal.',
  },
  smsEnabledDescription: {
    id: 'app.containers.ChatAgent.smsEnabledDescription',
    defaultMessage: 'AI Agent will be enabled to respond to inquires via SMS.',
  },
  smsWhatsappEnabledDescription: {
    id: 'app.containers.ChatAgent.smsWhatsappEnabledDescription',
    defaultMessage:
      'AI Agent will be enabled in Fountain SMS or WhatsApp, per applicant’s preferred communication choice.',
  },
  allBrandDisableMessage: {
    id: 'app.containers.ChatAgent.allBrandDisableMessage',
    defaultMessage:
      "To create an AI Agent for 'All Brands', remove any existing AI Agent that is set up for individual brand.",
  },
  allBrandDescription: {
    id: 'app.containers.ChatAgent.allBrandDescription',
    defaultMessage:
      "If 'All brands' is selected, the brand name, the color and logo of the default brand will be applied to the widget.",
  },
  individualBrandDescription: {
    id: 'app.containers.ChatAgent.individualBrandDescription',
    defaultMessage:
      'The color and logo of the selected brand will be applied to the widget.',
  },
  deleteAiAgent: {
    id: 'app.containers.ChatAgent.deleteAiAgent',
    defaultMessage: 'Delete AI Agent',
  },
  deleteModalDescription: {
    id: 'app.containers.ChatAgent.deleteModalDescription',
    defaultMessage:
      "Are you sure you want to delete this AI Agent? This action can't be undone.",
  },
  cancel: {
    id: 'app.containers.ChatAgent.cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'app.containers.ChatAgent.delete',
    defaultMessage: 'Delete',
  },
  unsavedChanges: {
    id: 'app.containers.ChatAgent.unsavedChanges',
    defaultMessage: 'Unsaved Changes',
  },
  unsavedModalDescription: {
    id: 'app.containers.ChatAgent.unsavedModalDescription',
    defaultMessage:
      'You have unsaved changes on this page, which will be lost if you don’t save. Do you want to continue editing or discard the changes?',
  },
  discard: {
    id: 'app.containers.ChatAgent.discard',
    defaultMessage: 'Discard',
  },
  continueEditing: {
    id: 'app.containers.ChatAgent.continueEditing',
    defaultMessage: 'Continue editing',
  },
  customWebsiteDescription: {
    id: 'app.containers.ChatAgent.customWebsiteDescription',
    defaultMessage:
      'AI Agent can be installed on the domain(s) you add below. Make sure to get the embed code and copy it to the domain(s) you provided.',
  },
  websiteDomain: {
    id: 'app.containers.ChatAgent.websiteDomain',
    defaultMessage: 'Website domain',
  },
  enterValidUrl: {
    id: 'app.containers.ChatAgent.enterValidUrl',
    defaultMessage: 'Please enter a valid URL',
  },
  deleteWebsiteDomain: {
    id: 'app.containers.ChatAgent.deleteWebsiteDomain',
    defaultMessage: 'Delete website domain',
  },
  deleteWebsiteDomainConfirmation: {
    id: 'app.containers.ChatAgent.deleteWebsiteDomainConfirmation',
    defaultMessage:
      'Are you sure you want to delete this website domain? The widget will be automatically removed from the website',
  },
  getEmbedCode: {
    id: 'app.containers.ChatAgent.getEmbedCode',
    defaultMessage: 'Get embed code',
  },
  getEmbedCodeDesc: {
    id: 'app.containers.ChatAgent.getEmbedCodeDesc',
    defaultMessage: 'Copy and paste this code to the domain(s) you provided.',
  },
  copyCode: {
    id: 'app.containers.ChatAgent.copyCode',
    defaultMessage: 'copy code',
  },
  agentFunctionTitle: {
    id: 'app.containers.ChatAgent.agentFunctionTitle',
    defaultMessage: 'Customize AI Agent function',
  },
  agentFunctionDesc: {
    id: 'app.containers.ChatAgent.agentFunctionDesc',
    defaultMessage: 'Select how you want the AI Agent to assist candidates.',
  },
  applyBotTitle: {
    id: 'app.containers.ChatAgent.applyBotTitle',
    defaultMessage:
      'Help candidate find a job and answer questions (recommended)',
  },
  applyBotDesc: {
    id: 'app.containers.ChatAgent.applyBotDesc',
    defaultMessage:
      'The AI Agent will display jobs for candidates to apply to and answer any questions they have about the job or application process.',
  },
  applyFaqBotTitle: {
    id: 'app.containers.ChatAgent.applyFaqBotTitle',
    defaultMessage: 'Answer candidate questions only',
  },
  applyFaqBotDesc: {
    id: 'app.containers.ChatAgent.applyFaqBotDesc',
    defaultMessage:
      'AI Agent will assist candidates with their application, application status, scheduling management, and answering questions from the knowledge base.',
  },
  testAiAgent: {
    id: 'app.containers.ChatAgent.testAiAgent',
    defaultMessage: 'Test AI Agent',
  },
  applyBotDisabled: {
    id: 'app.containers.ChatAgent.applyBotDisabled',
    defaultMessage: 'Please select a valid Bot Type',
  },
  noJobAvailableTooltip: {
    id: 'app.containers.ChatAgent.noJobAvailableTooltip',
    defaultMessage:
      'This option is disabled because 0 job is available in your account.',
  },
  oneJobAvailableTooltip: {
    id: 'app.containers.ChatAgent.oneJobAvailableTooltip',
    defaultMessage:
      'This option is disabled because there is only one job in your account.',
  },
  moreThanOneJobAvailableTooltip: {
    id: 'app.containers.ChatAgent.moreThanOneJobAvailableTooltip',
    defaultMessage:
      'This option is disabled because more than one job is available in your account.',
  },
  duplicateSettingCannotApplyAgain: {
    id: 'app.containers.ChatAgent.duplicateSettingCannotApplyAgain',
    defaultMessage:
      "Duplicate Rules setting is set to 'Cannot apply again’, which conflicts with AI Agent access to individual brand.",
  },
  jobsDirectoryCareerSiteUrl: {
    id: 'app.containers.ChatAgent.jobsDirectoryCareerSiteUrl',
    defaultMessage: 'Jobs Directory/Career site URL',
  },
  jobsDirectoryCareerSiteUrlInstruction: {
    id: 'app.containers.ChatAgent.jobsDirectoryCareerSiteUrlInstruction',
    defaultMessage:
      'Provide a career site or Jobs Directory URL you wish to display to applicants if they want to apply for a new job using the AI Agent from the Applicant Portal.',
  },
  invalidCareerSiteUrl: {
    id: 'app.containers.ChatAgent.invalidCareerSiteUrl',
    defaultMessage: 'Please enter a valid Career Site Url',
  },
  createConversationallyKb: {
    id: 'app.containers.ChatAgent.createConversationallyKb',
    defaultMessage: 'Create conversationally with AI',
  },
  createConversationallyKbDesc: {
    id: 'app.containers.ChatAgent.createConversationallyKbDesc',
    defaultMessage:
      'Fountain’s AI assistant will help build your Knowledge Base by automatically turning any material you upload into Knowledge Base and conversationally customize it to your needs.',
  },
  uploadKbCsvFile: {
    id: 'app.containers.ChatAgent.uploadKbCsvFile',
    defaultMessage: 'Upload .CSV file',
  },
  uploadKbCsvFileDesc: {
    id: 'app.containers.ChatAgent.uploadKbCsvFileDesc',
    defaultMessage:
      'Upload a .csv file to provide supplemental information for the AI Agent to review and read when answering questions that aren’t covered in the job description.',
  },
  buildKnowledgeBase: {
    id: 'app.containers.ChatAgent.buildKnowledgeBase',
    defaultMessage: 'Build knowledge base',
  },
});
