import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  optionsContainer: {
    display: 'flex',
  },
  optionDiv: {
    display: 'flex',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginRight: '12px',
    width: '45%',
    cursor: 'pointer',
  },
  optionButton: {
    marginRight: '12px',
  },
  optionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionTitle: {
    fontSize: '14px',
    marginBottom: '8px',
    color: '#131E40',
    lineHeight: '16px',
  },
  optionDescription: {
    fontSize: '14px',
    color: '#475569',
    lineHeight: '16px',
  },
}));
