import { WorkflowPartnerStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { PartnerRules } from 'containers/WorkflowEditor/components/Rules/PartnerRules';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { PartnerIntegrationCard } from './PartnerIntegrationCard';

export interface PartnerStageProps {
  stage: WorkflowPartnerStage;
  workflowId?: string;
}

export const PartnerStage: VFC<PartnerStageProps> = ({ stage, workflowId }) => {
  return (
    <div data-testid="partnerStage">
      <StageSection>
        <PartnerIntegrationCard stage={stage} workflowId={workflowId} />
      </StageSection>
      <StageSection>
        <PartnerRules />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="automated"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
