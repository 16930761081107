import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import { z } from 'zod';

import { ValidationResult } from 'hooks/useForm';

const SNAKE_CASE_REGEX = /^[a-z0-9]+(_[a-z0-9]+)*$/;

const commonDataFieldSchema = z.object({
  // we utilize the _id by adding it when a user adds a question
  // to account for the edgecase where before saving it they decide to
  // edit it bc editing assumes theres an id
  _id: z.any(),
  id: z.number().optional(),
  key: z
    .string()
    .trim()
    .min(1, { message: 'Required' })
    .regex(SNAKE_CASE_REGEX),
  question: z.string().trim().min(1, { message: 'Required' }),
  position: z.number().gte(0),
  required: z.boolean().default(true),
  hint: z.string().nullish(),
  hiring_manager_standard_question: z.boolean().nullish(),
  // we are making use of some rails magic here to handle the delete diffs
  // upside no complex logic needed on the BE to identify what was deletes
  // downside is the FE needs to account for this property and add it/filter
  // it accordingly where needed
  _destroy: z.boolean().optional(),
});

const textFieldSchema = commonDataFieldSchema.extend({
  type: z.literal(ReviewStageDataFieldTypeEnum.textField),
});

const datePickerFieldSchema = commonDataFieldSchema.extend({
  type: z.literal(ReviewStageDataFieldTypeEnum.datePickerField),
});

const radioFieldSchema = commonDataFieldSchema.extend({
  type: z.literal(ReviewStageDataFieldTypeEnum.radioField),
  options: z
    .array(
      z.object({
        _id: z.any(),
        value: z.string().trim().min(1),
        label: z.string().trim().min(1, { message: 'Required' }),
        visible: z.boolean().default(true),
      }),
    )
    .min(1, { message: 'at least 1 choice is required' }),
});

// we need to do this funky pipe with a flexible schema bc
// zod doesnt support doing transform with discriminated unions
export const dataFieldSchema = z.discriminatedUnion(
  'type',
  [textFieldSchema, radioFieldSchema, datePickerFieldSchema],
  { errorMap: () => ({ message: 'Required' }) },
);

export type DataField = z.infer<typeof dataFieldSchema>;
export type RadioDataField = Extract<
  DataField,
  { type: ReviewStageDataFieldTypeEnum.radioField }
>;
export type TextDataField = Extract<
  DataField,
  { type: ReviewStageDataFieldTypeEnum.textField }
>;
export type DatePickerDataField = Extract<
  DataField,
  { type: ReviewStageDataFieldTypeEnum.datePickerField }
>;
// we need to retype what errors look like for an array as the current
// generic doesnt account for such use cases
export type RadioErrors = Omit<ValidationResult<RadioDataField>, 'options'> & {
  options?: Record<number, string>;
};

const formBuilderSchema = z.object({
  dataFields: z.array(dataFieldSchema),
});

export type FormBuilder = z.infer<typeof formBuilderSchema>;
