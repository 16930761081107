import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../messages';
import { useConfirmationModalStyles } from './styles';

interface EmbedCodeModalProps {
  open: boolean;
  handleClose: () => void;
  agentId: string;
}

export const EmbedCodeModal: React.FC<EmbedCodeModalProps> = ({
  open,
  handleClose,
  agentId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useConfirmationModalStyles();

  const chatAgentWidgetUrl = localStorage.getItem(
    'chatAgentWidgetUrl',
  ) as string;
  const chatAgentBackendUrl = localStorage.getItem(
    'chatAgentBackendUrl',
  ) as string;

  const embedCode = `
      <script type="text/javascript">
        window.fountainAiConfig = {
          agentId: "${agentId}",
          uri: "${chatAgentWidgetUrl}",
          server: "${chatAgentBackendUrl}"
        };

        (function(){
          const script = document.createElement('script');
          script.src = "${chatAgentWidgetUrl}/ax-agent-widget.js";
          script.defer = true;
          document.head.appendChild(script);
        }());
      </script>
    `;

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(embedCode)
      .then(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.copiedToClipboard),
            'success',
          ),
        );
      })
      .catch(() => {
        dispatch(
          addMessageAction(intl.formatMessage(messages.failedToCopy), 'error'),
        );
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: classes.embedCodeContainer,
      }}
    >
      <DialogTitle classes={{ root: classes.modalTitle }}>
        <FormattedMessage {...messages.getEmbedCode} />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText>
          <FormattedMessage {...messages.getEmbedCodeDesc} />
        </DialogContentText>
        <Grid className={classes.copyCodeContainer}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={embedCode}
            InputProps={{
              readOnly: true,
            }}
            className={classes.embedCodeInput}
          />
          <Button
            onClick={handleCopyToClipboard}
            color="primary"
            variant="contained"
            className={classes.copyCodeBtn}
            classes={{ label: classes.copyBtnLabel }}
          >
            <CopyIcon />
            <FormattedMessage {...messages.copyCode} />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
