import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import { messages } from './messages';

export const ASSESSMENT_FORM_SCORE =
  'ApplicantLogic::Conditions::AssessmentFormScore';
export const DOCUMENT_UPLOADED = 'ApplicantLogic::Conditions::DocumentUploaded';
export const DUPLICATE_APPLICANT =
  'ApplicantLogic::Conditions::DuplicateApplicant';
export const FIELD_EXISTS = 'ApplicantLogic::Conditions::ApplicantDataExists';
export const FIELD_IS = 'ApplicantLogic::Conditions::ApplicantData';
export const FIELD_NOT_EXISTS =
  'ApplicantLogic::Conditions::ApplicantDataNotExists';
export const LESSONLY_SCORE = 'ApplicantLogic::Conditions::LessonlyScore';
export const LESSONLY_STATUS = 'ApplicantLogic::Conditions::LessonlyStatus';
export const NORTHPASS_COURSE_PROGRESS =
  'ApplicantLogic::Conditions::SchoolkeepCourseProgress';
export const NORTHPASS_QUIZ_SCORE =
  'ApplicantLogic::Conditions::SchoolkeepQuizScore';
export const PARTNER_DETAIL_HAS_KEY =
  'ApplicantLogic::Conditions::PartnerDetail::HasKey';
export const PARTNER_DETAIL_HAS_KEY_WITH_VALUE =
  'ApplicantLogic::Conditions::PartnerDetail::HasKeyWithValue';
export const PARTNER_LABEL_DOES_NOT_HAVE_KEY =
  'ApplicantLogic::Conditions::PartnerLabel::DoesNotHaveKey';
export const PARTNER_LABEL_HAS_KEY =
  'ApplicantLogic::Conditions::PartnerLabel::HasKey';
export const PARTNER_LABEL_HAS_KEY_WITH_VALUE =
  'ApplicantLogic::Conditions::PartnerLabel::HasKeyWithValue';
export const PARTNER_STATUS_IS =
  'ApplicantLogic::Conditions::PartnerStatus::Is';
export const PARTNER_STATUS_IS_NOT =
  'ApplicantLogic::Conditions::PartnerStatus::IsNot';
export const PARTNER_STATUS_HAS_TITLE =
  'ApplicantLogic::Conditions::PartnerStatus::HasTitle';
export const SCORE_CARD = 'ApplicantLogic::Conditions::ScoreCard';
export const SYNERGI_STATUS = 'ApplicantLogic::Conditions::SynergiStatus';
export const TRATIFY_PERSONALITY_SCORE =
  'ApplicantLogic::Conditions::TraitifyPersonalityScore';
export const W4_FEDERAL_FORM_STATUS =
  'ApplicantLogic::Conditions::W4FederalFormStatus';
export const I9_FORM_STATUS = 'ApplicantLogic::Conditions::I9FormStatus';
export const EVERIFY_CASE_STATUS =
  'ApplicantLogic::Conditions::EverifyCaseStatus';
export const EVERIFY_CASE_ELIGIBILITY_STATEMENT =
  'ApplicantLogic::Conditions::EverifyCaseEligibilityStatement';
export const TECH_CHECK_STATUS = 'ApplicantLogic::Conditions::TechCheckStatus';
export const ACCURATE_STATUS = 'ApplicantLogic::Conditions::AccurateStatus';
export const APPLICANT_DATA = 'ApplicantLogic::Conditions::ApplicantData';
export const APPLICANT_AGE = 'ApplicantLogic::Conditions::ApplicantAge';
export const APPLICANT_DATA_EXISTS =
  'ApplicantLogic::Conditions::ApplicantDataExists';
export const APPLICANT_DATA_NOT_EXISTS =
  'ApplicantLogic::Conditions::ApplicantDataNotExists';
export const APPLICANT_LABEL_IS =
  'ApplicantLogic::Conditions::ApplicantLabelIs';
export const APPLICANT_LABEL_IS_NOT =
  'ApplicantLogic::Conditions::ApplicantLabelIsNot';
export const APPLICANT_LOCATION =
  'ApplicantLogic::Conditions::ApplicantLocation';
export const APPLICANT_DATA_COMPARISON =
  'ApplicantLogic::Conditions::ApplicantDataComparison';
export const CHECKR_STATUS = 'ApplicantLogic::Conditions::CheckrStatus';
export const CUSTOMER_ATTRIBUTE =
  'ApplicantLogic::Conditions::CustomerAttribute';
export const DOCU_SIGN_STATUS = 'ApplicantLogic::Conditions::DocuSignStatus';
export const EMBEDDED_SIGN_STATUS =
  'ApplicantLogic::Conditions::EmbeddedSignStatus';
export const HELLO_SIGN_STATUS = 'ApplicantLogic::Conditions::HelloSignStatus';
export const ONFIDO_STATUS = 'ApplicantLogic::Conditions::OnfidoStatus';
export const SCORE_CARD_RESULT = 'ApplicantLogic::Conditions::ScoreCardResult';
export const STERLING_SERVICE_LEVEL_RESULTS =
  'ApplicantLogic::Conditions::SterlingServiceLevelResults';
export const STERLING_STATUS = 'ApplicantLogic::Conditions::SterlingStatus';
export const TRATIFY_PERSONALITY_RESULT =
  'ApplicantLogic::Conditions::TraitifyPersonalityResult';
export const SCHOOLKEEP_COURSE_PROGRESS =
  'ApplicantLogic::Conditions::SchoolkeepCourseProgress';
export const SCHOOLKEEP_QUIZ_SCORE =
  'ApplicantLogic::Conditions::SchoolkeepQuizScore';
export const TRAITIFY_PERSONALITY_RESULT =
  'ApplicantLogic::Conditions::TraitifyPersonalityResult';

export const CLEAR = 'clear';
export const CONSIDER = 'consider';
export const CONSENTED = 'consented';
export const COMPLETED = 'completed';
export const NOT_CONSENTED = 'not consented';
export const NOT_COMPLETED = 'not_completed';
export const PENDING = 'pending';
export const CANCELED = 'canceled';
export const SUSPENDED = 'suspended';
export const ENGAGED = 'engaged';
export const RUNNING = 'running';
export const NEEDS_REVIEW = 'needs review';
export const POST_ADVERSE_ACTION = 'post_adverse_action';
export const DISPUTE = 'dispute';

// Document Signging statuses
export const UNOPENED = 'unopened';
export const OPENED = 'opened';
export const SIGNED_BY_APPLICANT = 'signed_by_applicant';
export const SIGNED_BY_ALL_PARTIES = 'signed_by_all_parties';
export const COMPLETE = 'Complete';
export const DECLINED = 'Declined';
export const INCOMPLETE = 'Incomplete';
export const IN_PROGRESS = 'In Progress';
export const PENDING_ACTION = 'Pending Action';
export const VOIDED = 'Voided';

// I9 Form statuses
export const AWAITING_AUTHORIZED_REPRESENTATIVE =
  'awaiting_authorized_representative';
export const AWAITING_EMPLOYEE = 'awaiting_employee';
export const AWAITING_EMPLOYER = 'awaiting_employer';
export const FORM_PENDING = 'pending';
export const FORM_COMPLETE = 'complete';
export const OPTED_OUT = 'opted_out';

// Everify Statuses
export const CLOSE_CASE_AND_RESUBMIT = 'CLOSE_CASE_AND_RESUBMIT';
export const CLOSED = 'CLOSED';
export const DRAFT = 'DRAFT';
export const EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED';
export const FINAL_NONCONFIRMATION = 'FINAL_NONCONFIRMATION';
export const MANUAL_REVIEW = 'MANUAL_REVIEW';
export const PENDING_REFERRAL = 'PENDING_REFERRAL';
export const PHOTO_MATCH = 'PHOTO_MATCH';
export const QUEUED = 'QUEUED';
export const REFERRED = 'REFERRED';
export const SCAN_AND_UPLOAD = 'SCAN_AND_UPLOAD';
export const UNCONFIRMED_DATA = 'UNCONFIRMED_DATA';

// Everify Eligibility Statements (only those that aren't duplicated as statuses)
export const NO_ACTION_FNC = 'NO_ACTION_FNC';
export const NO_SHOW = 'NO_SHOW';
export const NOT_AUTHORIZED_EMPLOYMENT = 'NOT_AUTHORIZED_EMPLOYMENT';
export const RESUBMIT_CASE = 'RESUBMIT_CASE';

export const generateHashStatusMessages = (intl: IntlShape) => ({
  [CLEAR]: intl.formatMessage(messages.clear),
  [CONSIDER]: intl.formatMessage(messages.consider),
  [PENDING]: intl.formatMessage(messages.pending),
  [CANCELED]: intl.formatMessage(messages.canceled),
  [CONSENTED]: intl.formatMessage(messages.consented),
  [COMPLETED]: intl.formatMessage(messages.completed),
  [SUSPENDED]: intl.formatMessage(messages.suspended),
  [ENGAGED]: intl.formatMessage(messages.engaged),
  [NOT_CONSENTED]: intl.formatMessage(messages.notConsented),
  [NOT_COMPLETED]: intl.formatMessage(messages.notCompleted),
  [RUNNING]: intl.formatMessage(messages.running),
  [NEEDS_REVIEW]: intl.formatMessage(messages.needsReview),
  [POST_ADVERSE_ACTION]: intl.formatMessage(messages.postAdverseAction),
  [DISPUTE]: intl.formatMessage(messages.dispute),
});

export const documentSigningStatusesHash = {
  [UNOPENED]: 'Unopened',
  [OPENED]: 'Opened',
  [SIGNED_BY_APPLICANT]: 'Signed by applicant',
  [SIGNED_BY_ALL_PARTIES]: 'Signed by all parties',
  [COMPLETE]: 'Complete',
  [DECLINED]: 'Declined',
  [INCOMPLETE]: 'Incomplete',
  [IN_PROGRESS]: 'In Progress',
  [PENDING_ACTION]: 'Pending Action',
  [VOIDED]: 'Voided',
};

// These must match exactly the values defined in monolith repo at: app/models/everify_case.rb
export const everifyCaseStatusesHash = (intl: IntlShape) => ({
  [CLOSE_CASE_AND_RESUBMIT]: intl.formatMessage(messages.closeCaseAndResubmit),
  [CLOSED]: intl.formatMessage(messages.closed),
  [DRAFT]: intl.formatMessage(messages.draft),
  [EMPLOYMENT_AUTHORIZED]: intl.formatMessage(messages.employmentAuthorized),
  [FINAL_NONCONFIRMATION]: intl.formatMessage(messages.finalConfirmation),
  [MANUAL_REVIEW]: intl.formatMessage(messages.manualReview),
  [PENDING_REFERRAL]: intl.formatMessage(messages.pendingReferral),
  [PHOTO_MATCH]: intl.formatMessage(messages.photoMatch),
  [QUEUED]: intl.formatMessage(messages.queued),
  [REFERRED]: intl.formatMessage(messages.referred),
  [SCAN_AND_UPLOAD]: intl.formatMessage(messages.scanAndUpload),
  [UNCONFIRMED_DATA]: intl.formatMessage(messages.unconfirmedData),
});

export const everifyCaseEligibilityStatementsHash = (intl: IntlShape) => ({
  [EMPLOYMENT_AUTHORIZED]: intl.formatMessage(messages.employmentAuthorized),
  [NO_ACTION_FNC]: intl.formatMessage(messages.noActionFnc),
  [NO_SHOW]: intl.formatMessage(messages.noShow),
  [NOT_AUTHORIZED_EMPLOYMENT]: intl.formatMessage(
    messages.notAuthorizedEmployment,
  ),
  [RESUBMIT_CASE]: intl.formatMessage(messages.resubmitCase),
});

export const generateFormStatusMessages = (
  intl: IntlShape,
  formStatusType: typeof I9_FORM_STATUS | typeof W4_FEDERAL_FORM_STATUS,
) => {
  const formTypesHash: Record<
    typeof I9_FORM_STATUS | typeof W4_FEDERAL_FORM_STATUS,
    string
  > = {
    [I9_FORM_STATUS]: intl.formatMessage(messages.i9),
    [W4_FEDERAL_FORM_STATUS]: intl.formatMessage(messages.w4Fed),
  };

  const formType = formTypesHash[formStatusType];

  return {
    ...(formStatusType === I9_FORM_STATUS
      ? {
          [AWAITING_AUTHORIZED_REPRESENTATIVE]: intl.formatMessage(
            messages.awaitingAuthorizedRepresentative,
          ),
        }
      : {}),
    [AWAITING_EMPLOYEE]: intl.formatMessage(messages.awaitingEmployee, {
      formType,
    }),
    [AWAITING_EMPLOYER]: intl.formatMessage(messages.awaitingEmployer, {
      formType,
    }),
    [FORM_PENDING]: intl.formatMessage(messages.formPending, { formType }),
    [FORM_COMPLETE]: intl.formatMessage(messages.formCompleted, { formType }),
    [OPTED_OUT]: intl.formatMessage(messages.optedOut, { formType }),
  };
};

export const comparators = (
  intl: IntlShape,
  options?: { onlyReturnBasicComparators?: boolean },
) => [
  { value: 'eq', label: intl.formatMessage(messages.equals) },
  { value: 'ne', label: intl.formatMessage(messages.doesNotEqual) },
  { value: 'gt', label: intl.formatMessage(messages.greaterThan) },
  { value: 'gte', label: intl.formatMessage(messages.greaterThanOrEqual) },
  { value: 'lt', label: intl.formatMessage(messages.lessThan) },
  { value: 'lte', label: intl.formatMessage(messages.lessThanOrEqual) },
  ...(!options?.onlyReturnBasicComparators
    ? [
        { value: 'matches', label: intl.formatMessage(messages.includes) },
        {
          value: 'not_matches',
          label: intl.formatMessage(messages.doesNotInclude),
        },
        { value: 'in', label: intl.formatMessage(messages.isInTheList) },
      ]
    : []),
];

export const documentSigningStatusByProvider: Record<string, string> = {
  docusign: DOCU_SIGN_STATUS,
  hellosign: HELLO_SIGN_STATUS,
  white_labeled_hellosign: EMBEDDED_SIGN_STATUS,
};

export const getConditionErrorMessage = (
  key: string,
  errors?: {
    [key: string]: string[];
  },
) => {
  if (errors === undefined) {
    return undefined;
  }

  const errorMessage = errors?.[
    `rule_set.rules.condition_set.conditions.${key}`
  ] ||
    errors?.[key] || <FormattedMessage {...messages.cantBeBlank} />;

  if (Array.isArray(errorMessage)) {
    return errorMessage.join(', ');
  }

  return errorMessage;
};
