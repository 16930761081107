import { defineMessages } from 'react-intl';

export const commonQuestionFormMessages = defineMessages({
  questionFormQuestionLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.CommonQuestionForm.questionFormQuestionLabel`,
    defaultMessage: 'Question title',
  },
  questionFormQuestionTypeLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.CommonQuestionForm.questionFormQuestionTypeLabel`,
    defaultMessage: 'Question type',
  },
  questionFormQuestionHintLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.CommonQuestionForm.questionFormQuestionHintLabel`,
    defaultMessage: 'Hint text (optional)',
  },
  questionFormQuestionDataKeyLabel: {
    id: `app.components.FormBuilder.QuestionTypeForms.CommonQuestionForm.questionFormQuestionDataKeyLabel`,
    defaultMessage: 'Data key',
  },
});
