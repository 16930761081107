import { Intercom, show } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

export const IntercomMessenger = () => {
  /* eslint-disable camelcase */
  const {
    external_id: user_id,
    name,
    email,
    intercom_digest: user_hash,
    created_at: createdAtISO,
  } = useSelector(makeSelectWhoami());
  if (!createdAtISO) {
    return null;
  }

  const created_at = Date.parse(createdAtISO);
  Intercom({
    app_id: 'h9dh2723',
    user_id,
    user_hash,
    name,
    email,
    created_at,
  });

  show();
  /* eslint-enable camelcase */
  return null;
};
