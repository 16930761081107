import { EventsService } from 'api-clients/monolith';
import { useCallback } from 'react';

import { useApiService } from 'hooks/useApiService';

export const useStageTitles = ({
  query,
  hostId,
}: {
  query: string;
  hostId?: string;
}) => {
  const fetch = useCallback(
    () => EventsService.getInternalApiEventsStageTitles(query, hostId),
    [query, hostId],
  );

  return useApiService(fetch);
};
