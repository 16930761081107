import { EventsService } from 'api-clients/monolith';
import { useCallback } from 'react';

import { useApiService } from 'hooks/useApiService';

export const useStageOnSiteAddresses = ({
  user,
  stageTitles,
  openingTitles,
  locationGroupTitles,
}: {
  user?: string;
  stageTitles: Array<string>;
  openingTitles?: Array<string>;
  locationGroupTitles?: Array<string>;
}) => {
  const locationService = useCallback(
    () =>
      EventsService.getInternalApiEventsConditions(
        stageTitles,
        openingTitles,
        locationGroupTitles,
        user,
      ),
    [locationGroupTitles, openingTitles, stageTitles, user],
  );
  const { result } = useApiService(locationService);
  return { result };
};
