import { Dropdown } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { Action as ActionType, Condition } from 'api-clients/monolith';
import produce from 'immer';
import React, { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import {
  ACCURATE_STATUS,
  APPLICANT_DATA_EXISTS,
  APPLICANT_DATA_NOT_EXISTS,
  CHECKR_STATUS,
  CONSENTED,
} from '../Condition/constants';
import { RowDeleteButton } from '../RowDeleteButton';
import { RowTag } from '../RowTag';
import { RulesProps, SharedActionProps } from '../types';
import { BackgroundConsentData } from './BackgroundConsentData';
import {
  CREATE_OR_UPDATE_WX_WORKER,
  INCREMENT_APPLICANT_VALUE,
  MOVE_TO_NEXT_STAGE,
  MOVE_TO_POSITION,
  MOVE_TO_STAGE,
  RESUME_ONFIDO_REPORTS,
  RUN_ACCURATE_REPORTS,
  RUN_CHECKR_REPORTS,
  SWITCH_APPLICANT_DATA,
  UPDATE_APPLICANT_DATA,
} from './constants';
import { IncrementApplicantValue } from './IncrementApplicantValue';
import { messages } from './messages';
import { MoveToPosition } from './MoveToPosition';
import { MoveToStage } from './MoveToStage';
import { useStyles } from './styles';
import { SwitchApplicantData } from './SwitchApplicantData';
import { UpdateApplicantData } from './UpdateApplicantData';

export interface ActionComponentProps extends SharedActionProps {
  allowDelete: boolean;
  index: number;
  conditions?: Condition[];
  actions?: ActionType[];
}

export const Action: FC<ActionComponentProps> = ({
  index,
  action,
  allowDelete = false,
  setRules,
  ruleId,
  conditions = [],
  actions = [],
  errors,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const whoami = useSelector(makeSelectWhoami());

  const onSelectActionType = (type: string) => {
    setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

        const idx = draftActions.findIndex(act => act.id === action.id);

        draftActions[idx] = {
          id: action.id,
          type,
          extra: {},
          ref_stage_1_id: null,
        };
      }),
    );
  };

  const onDeleteAction = () => {
    setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

        const idx = draftActions.findIndex(act => act.id === action.id);

        // eslint-disable-next-line no-underscore-dangle
        draftActions[idx]._destroy = true;
      }),
    );
  };

  const onfidoCondition = false;
  const checkrCondition = conditions.some(
    condition =>
      condition.type === CHECKR_STATUS && condition.extra.status === CONSENTED,
  );
  const accurateCondition = conditions.some(
    condition =>
      condition.type === ACCURATE_STATUS &&
      condition.extra.status === CONSENTED,
  );
  const isApplicantData = conditions.find(
    condition =>
      condition.type === APPLICANT_DATA_EXISTS ||
      condition.type === APPLICANT_DATA_NOT_EXISTS,
  );

  const actionTypeOptions = () => {
    const ruleActionTypes = [
      {
        label: intl.formatMessage(messages.moveApplicantToNextStage),
        value: MOVE_TO_NEXT_STAGE,
      },
      {
        label: intl.formatMessage(messages.moveApplicantToStage),
        value: MOVE_TO_STAGE,
      },
      {
        label: intl.formatMessage(messages.moveApplicantToOpening),
        value: MOVE_TO_POSITION,
      },
      {
        label: intl.formatMessage(messages.updateApplicantData),
        value: UPDATE_APPLICANT_DATA,
      },
      {
        label: intl.formatMessage(messages.incrementApplicantData),
        value: INCREMENT_APPLICANT_VALUE,
      },
    ];

    if (whoami.wx_applicant_sync_webhooks_exist) {
      ruleActionTypes.push({
        label: intl.formatMessage(messages.createOrUpdateWxWorker),
        value: CREATE_OR_UPDATE_WX_WORKER,
      });
    }

    if (onfidoCondition) {
      ruleActionTypes.push({
        label: intl.formatMessage(messages.runConsentedBackgroundCheckReport),
        value: RESUME_ONFIDO_REPORTS,
      });
    }

    if (checkrCondition) {
      ruleActionTypes.push({
        label: intl.formatMessage(messages.runConsentedBackgroundCheckReport),
        value: RUN_CHECKR_REPORTS,
      });
    }

    if (accurateCondition) {
      ruleActionTypes.push({
        label: intl.formatMessage(
          messages.resumeConsentedBackgroundCheckReport,
        ),
        value: RUN_ACCURATE_REPORTS,
      });
    }

    if (isApplicantData) {
      ruleActionTypes.push({
        label: intl.formatMessage(messages.switchApplicantData),
        value: SWITCH_APPLICANT_DATA,
      });
    }

    return ruleActionTypes;
  };

  // If any condition type is CHECKR_STATUS or ACCURATE_STATUS and has the CONSENTED status selected,
  // we want to clear out any actions that have RUN_CHECKR_REPORTS or RUN_ACCURATE_REPORTS selected and
  // reset the action type to be MOVE_TO_STAGE
  const clearOutRunReportActions = useCallback(() => {
    setRules(
      produce((draftRules: RulesProps) => {
        const draftActions =
          draftRules[ruleId]?.action_set_attributes?.actions_attributes ?? [];

        draftActions.forEach(draftAction => {
          if (
            (!checkrCondition && draftAction.type === RUN_CHECKR_REPORTS) ||
            (!accurateCondition && draftAction.type === RUN_ACCURATE_REPORTS)
          ) {
            draftAction.ref_stage_1_id = null;
            draftAction.type = MOVE_TO_STAGE;
          }
        });
      }),
    );
  }, [accurateCondition, checkrCondition, ruleId, setRules]);

  useEffect(() => {
    if (!accurateCondition || !checkrCondition) {
      const hasRunReportAction = actions.some(
        act =>
          act.type === RUN_CHECKR_REPORTS || act.type === RUN_ACCURATE_REPORTS,
      );

      if (hasRunReportAction) {
        clearOutRunReportActions();
      }
    }
  }, [accurateCondition, actions, checkrCondition, clearOutRunReportActions]);

  const sharedActionProps = {
    setRules,
    action,
    ruleId,
    index,
    errors,
  };

  return (
    <Grid container className={styles.actionRow}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
        className={styles.tagContainer}
      >
        <RowTag
          classes={{ tag: styles.rowTag }}
          label={
            index === 0
              ? intl.formatMessage(messages.action)
              : intl.formatMessage(messages.and)
          }
        />
        <RowDeleteButton
          allowDelete={allowDelete}
          onDelete={onDeleteAction}
          ariaLabel={intl.formatMessage(messages.deleteActionNumber, {
            number: index + 1,
          })}
        />
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Dropdown
            options={actionTypeOptions()}
            value={action.type}
            onChange={onSelectActionType}
            label={intl.formatMessage(messages.actionType)}
            aria-label={intl.formatMessage(messages.actionType)}
            isSearchable
            required
          />
        </Grid>
        {action.type &&
          {
            [MOVE_TO_STAGE]: <MoveToStage {...sharedActionProps} />,
            [MOVE_TO_NEXT_STAGE]: null,
            [MOVE_TO_POSITION]: <MoveToPosition {...sharedActionProps} />,
            [RUN_ACCURATE_REPORTS]: (
              <BackgroundConsentData {...sharedActionProps} />
            ),
            [RUN_CHECKR_REPORTS]: (
              <BackgroundConsentData {...sharedActionProps} />
            ),
            [SWITCH_APPLICANT_DATA]: (
              <SwitchApplicantData {...sharedActionProps} />
            ),
            [UPDATE_APPLICANT_DATA]: (
              <UpdateApplicantData {...sharedActionProps} />
            ),
            [INCREMENT_APPLICANT_VALUE]: (
              <IncrementApplicantValue {...sharedActionProps} />
            ),
          }[action.type]}
      </Grid>
    </Grid>
  );
};
