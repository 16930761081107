import {
  CancelablePromise,
  Count,
  EventsService,
  Unauthorized,
} from 'api-clients/monolith';
import { UpdateModeType } from 'Calendar/CreateEvent/types';

import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

type UseDeleteEventOptions = {
  onSuccess?: (response: Count) => void;
  onError?: (response: Unauthorized | undefined) => void;
};

export const useDeleteEvent = (options?: UseDeleteEventOptions) => {
  return useApiServiceMutation<
    Count,
    (
      eventExternalId: string,
      params: {
        // eslint-disable-next-line camelcase
        delete_mode: UpdateModeType;
      },
    ) => CancelablePromise<Count>,
    Unauthorized
  >((...args) => EventsService.deleteInternalApiEvents(...args), {
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
