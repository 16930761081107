import { Button, IconButton, Input } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error as InputError } from 'components/Error';

import { createNewRadioOption } from './helpers';
import { useRadioDataField } from './hooks';
import { multipleChoiceQuestionFormMessages } from './messages';
import { useMultipleChoiceQuestionFormStyles } from './styles';

const {
  addOptionButtonText,
  questionFormOptionChoiceLabel,
  questionFormOptionChoicePlaceholder,
  questionFormOptionDeleteText,
  header,
} = multipleChoiceQuestionFormMessages;

export const MultipleChoiceForm = () => {
  const intl = useIntl();
  const classes = useMultipleChoiceQuestionFormStyles();

  const { values, errors, handleChange, arrayHelpers } = useRadioDataField();

  const options = Array.isArray(values?.options) ? values.options : [];

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          className={classes.optionsContainer}
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage {...header} />
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              type="secondary"
              size="small"
              autoWidth
              onClick={() => {
                handleChange({
                  options: arrayHelpers.insertAt(
                    options,
                    createNewRadioOption(),
                    'end',
                  ),
                });
              }}
            >
              <Typography
                variant="body2"
                color="primary"
                className={classes.addOptionButtonText}
              >
                <Add className={classes.addOptionButtonIcon} />
                <FormattedMessage {...addOptionButtonText} />
              </Typography>
            </Button>
          </Grid>
          <Grid xs={12}>
            <ul className={classes.optionList}>
              {options.map((opt, idx) => {
                const hasError = Boolean(errors?.options?.[idx]);
                return (
                  // eslint-disable-next-line no-underscore-dangle
                  <li key={opt._id as string} className={classes.option}>
                    <div className={classes.optionInputContainer}>
                      <Input
                        aria-label={intl.formatMessage(
                          questionFormOptionChoiceLabel,
                        )}
                        placeholder={intl.formatMessage(
                          questionFormOptionChoicePlaceholder,
                        )}
                        value={options[idx].label}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          const { value } = event.target;
                          const updateIdx = options.indexOf(opt);

                          handleChange({
                            options: arrayHelpers.updateAt(
                              options,
                              { ...opt, value, label: value },
                              updateIdx,
                            ),
                          });
                        }}
                        error={hasError}
                        required
                      />

                      <IconButton
                        size="small"
                        aria-label={intl.formatMessage(
                          questionFormOptionDeleteText,
                        )}
                        onClick={() => {
                          const removeIdx = options.indexOf(opt);
                          handleChange({
                            options: arrayHelpers.removeAt(options, removeIdx),
                          });
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                    <div
                      className={clsx(classes.optionInputErrorContainer, {
                        [classes.optionInputErrorContainerHeight]:
                          Boolean(hasError),
                      })}
                    >
                      <InputError
                        error={errors?.options?.[idx]}
                        align="right"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
