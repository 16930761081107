import { Reason, WorkflowArchivedStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';
import { defaultReason } from 'containers/WorkflowEditor/components/StageSettingMessageCard/components/MessageRow/MessageRow.fixtures';
import { useArchivedReasons } from 'containers/WorkflowEditor/hooks/useArchivedReasons';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';

export interface ArchivedStageProps {
  stage: WorkflowArchivedStage;
}

export const ArchivedStage: VFC<ArchivedStageProps> = ({ stage }) => {
  const { result: archivedReasonsResult } = useArchivedReasons();

  let reasons: Reason[] = [defaultReason];
  if (archivedReasonsResult.status === 'ready') {
    reasons = reasons.concat(archivedReasonsResult.data.archived_reasons);
  }

  return (
    <div data-testid="archivedStage">
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="archived"
          stageExternalId={stage.external_id}
          reasons={reasons}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      {stage.account_go_enabled && (
        <StageSection>
          <AdvancedSettings stage={stage} />
        </StageSection>
      )}
    </div>
  );
};
