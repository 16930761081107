import { CancelablePromise, CancelError } from 'api-clients/monolith';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addDefaultErrorMessageActionI18n } from 'containers/FlashMessage/actions';

type QueryKey = number;
/**
 * data is the aggregation of all results loaded
 * lastResult is the most recently loaded values
 * fetchKey will load that key if it has not already been loaded
 * clear wipes out the cache
 */
export interface useAggregatorApiService<T> {
  data: { [key: QueryKey]: T };
  fetchKey: (key: QueryKey) => void;
  clear: () => void;
}

// caching API service, if you request the same cache key you will get stored results
export const useAggregatorApiService = <T>(
  // This service should be memoized before passed in, for example by using `useCallback`.
  service: (key: QueryKey) => CancelablePromise<T>,
): useAggregatorApiService<T> => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [queryKey, setQueryKey] = useState<QueryKey>();

  const fetchKey = (key: QueryKey) => {
    setQueryKey(key);
  };

  const clear = () => {
    setQueryKey(undefined);
    setData({});
  };

  useEffect(() => {
    if (queryKey === undefined || queryKey in data) {
      return () => {};
    }
    const promise = service(queryKey);
    promise
      .then(loadedData => {
        setData(data => ({ [queryKey]: loadedData, ...data }));
      })
      .catch((e: Error) => {
        if (e instanceof CancelError) {
          return;
        }
        dispatch(addDefaultErrorMessageActionI18n(intl));
      });
    return () => promise.cancel();
  }, [service, queryKey, data, dispatch, intl]);

  return { data, fetchKey, clear };
};
