import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { makeStyles, Typography } from '@material-ui/core';
import {
  WorkflowJobMatcherStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import globalMessages from 'shared/global/messages';

import { SettingSwitch } from '../../../SettingSwitch';
import { AutoAdvanceWhenLabelsComplete } from '../../SettingsSwitches';
import { messages } from './messages';

const useStyles = makeStyles(theme => ({
  settings: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderTop: theme.customBorders.gray300,
  },
  switchesContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
    paddingLeft: theme.spacing(-0.25),
  },
  distanceSelect: {
    maxWidth: '250px',
  },
}));

type DistanceLimitValue = 25 | 50 | 100;

type DistanceLimitOption = {
  label: React.ReactNode;
  value: DistanceLimitValue;
};

const DEFAULT_DISTANCE_LIMIT_VALUE: DistanceLimitValue = 25;

export interface AdvancedOptionsProps {
  stage: WorkflowJobMatcherStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const AdvancedOptions: VFC<AdvancedOptionsProps> = ({
  stage,
  setStage,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  const distanceLimit = stage.additional_info.job_distance_limit_miles;
  const hasStageLabels = Boolean(stage.has_stage_labels);
  const isDistanceLimitEnabled = !!distanceLimit;
  const isSubstage = Boolean(stage.parent_stage_id);

  const onToggleChange =
    (key: keyof WorkflowJobMatcherStage['advanced_settings']) => () => {
      setStage(
        produce(stage, draftStage => {
          draftStage.advanced_settings[key] =
            !draftStage.advanced_settings[key];
        }),
      );
    };

  const onDistanceLimitToggle = () => {
    const jobLimitValue = isDistanceLimitEnabled
      ? null
      : DEFAULT_DISTANCE_LIMIT_VALUE;

    setStage({
      ...stage,
      additional_info: {
        ...stage.additional_info,
        job_distance_limit_miles: jobLimitValue,
      },
    });
  };

  const onDistanceLimitDropdownChange = ({ value }: DistanceLimitOption) => {
    setStage({
      ...stage,
      additional_info: {
        ...stage.additional_info,
        job_distance_limit_miles: value,
      },
    });
  };

  const distanceOptionsInMiles: DistanceLimitOption[] = [
    {
      label: intl.formatMessage(messages.numberOfMiles, {
        miles: 25,
      }),
      value: 25,
    },
    {
      label: intl.formatMessage(messages.numberOfMiles, {
        miles: 50,
      }),
      value: 50,
    },
    {
      label: intl.formatMessage(messages.numberOfMiles, {
        miles: 100,
      }),
      value: 100,
    },
  ];

  return (
    <div className={styles.settings}>
      <Typography variant="h4" color="textPrimary">
        <FormattedMessage {...messages.advancedOptions} />
      </Typography>
      <div className={styles.switchesContainer}>
        <SettingSwitch
          dataKey="is_hidden_from_portal"
          checked={!stage.advanced_settings.is_hidden_from_portal ?? true}
          onChange={onToggleChange('is_hidden_from_portal')}
          label={
            <FormattedMessage {...globalMessages.showStageInApplicantPortal} />
          }
        />
        <SettingSwitch
          dataKey="filter-by-distance-toggle"
          checked={isDistanceLimitEnabled}
          onChange={onDistanceLimitToggle}
          label={<FormattedMessage {...messages.toggleDistanceLimit} />}
          fieldComponent={
            isDistanceLimitEnabled ? (
              <StyledReactSelect
                className={styles.distanceSelect}
                aria-label={intl.formatMessage(messages.distanceLimitSelect)}
                onChange={onDistanceLimitDropdownChange}
                options={distanceOptionsInMiles}
                value={distanceOptionsInMiles.find(
                  ({ value }) =>
                    value === stage.additional_info.job_distance_limit_miles,
                )}
              />
            ) : null
          }
        />
        <SettingSwitch
          dataKey="allow_applicants_to_advance"
          disabled={isSubstage}
          checked={
            stage.advanced_settings.should_applicant_self_advance ?? false
          }
          onChange={onToggleChange('should_applicant_self_advance')}
          label={<FormattedMessage {...messages.allowApplicantsToAdvance} />}
        />
        {!isSubstage && hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
      </div>
    </div>
  );
};
