/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttributeEntity } from '../models/AttributeEntity';
import type { AttributeEntityParams } from '../models/AttributeEntityParams';
import type { AttributeType } from '../models/AttributeType';
import type { AttributeValue } from '../models/AttributeValue';
import type { CustomerAttribute } from '../models/CustomerAttribute';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerAttributesService {

    /**
     * Gets attribute types grouped by entity type
     * Get attribute types grouped by entity type
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCustomerAttributesAttributeEntities(): CancelablePromise<{
        LOCATION?: Array<AttributeEntity>;
        LOCATIONGROUP?: Array<AttributeEntity>;
        OPENING?: Array<AttributeEntity>;
        POSITION?: Array<AttributeEntity>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/customer_attributes/attribute_entities',
        });
    }

    /**
     * Get all attribute types for the account
     * Get attributes types for the current account
     * @returns AttributeType successful
     * @throws ApiError
     */
    public static getInternalApiCustomerAttributesAttributeTypes(): CancelablePromise<Array<AttributeType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/customer_attributes/attribute_types',
        });
    }

    /**
     * Get attribute values for given attribute type
     * Get attributes for a given entity
     * @param typeId
     * @returns AttributeValue successful
     * @throws ApiError
     */
    public static getInternalApiCustomerAttributesAttributeValues(
        typeId?: number,
    ): CancelablePromise<Array<AttributeValue>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/customer_attributes/attribute_values',
            query: {
                'type_id': typeId,
            },
        });
    }

    /**
     * Gets customer attributes assigned to specified entity
     * Get attributes for a given entity
     * @param entityClass
     * @param id
     * @returns CustomerAttribute successful
     * @throws ApiError
     */
    public static getInternalApiCustomerAttributesEntities(
        entityClass?: string,
        id?: number,
    ): CancelablePromise<Array<CustomerAttribute>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/customer_attributes/entities',
            query: {
                'entity_class': entityClass,
                'id': id,
            },
        });
    }

    /**
     * Updates the attribute values assigned to the entity
     * Update attributes for a given entity
     * @param requestBody
     * @returns CustomerAttribute successful
     * @throws ApiError
     */
    public static putInternalApiCustomerAttributesEntities(
        requestBody?: AttributeEntityParams,
    ): CancelablePromise<Array<CustomerAttribute>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/customer_attributes/entities',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `forbidden`,
            },
        });
    }

}