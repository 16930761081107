import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SharedActionProps } from 'containers/WorkflowEditor/components/Rules/types';

import { DataKeySelect } from '../components/DataKeySelect';
import { InputField } from '../components/InputField';
import { messages } from './messages';

export const IncrementApplicantValue: FC<SharedActionProps> = React.memo(
  props => {
    const intl = useIntl();

    return (
      <>
        <DataKeySelect
          {...props}
          label={intl.formatMessage(messages.dataKey)}
        />
        <Grid item xs={6}>
          <InputField
            action={props.action}
            ruleId={props.ruleId}
            setRules={props.setRules}
            errors={props.errors}
            label={intl.formatMessage(messages.changeBy)}
          />
        </Grid>
      </>
    );
  },
);
