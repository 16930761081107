import { Alert } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormBuilder } from 'components/FormBuilder';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { useUpdateStageResultContextState } from './context';
import { messages } from './messages';
import { useStyles } from './styles';

export const ReviewQuestionnaire = () => {
  const classes = useStyles();
  const intl = useIntl();
  const updateStageResult = useUpdateStageResultContextState();

  return (
    <Grid>
      {updateStageResult?.isError && updateStageResult.error && (
        <Alert fullWidth type="danger">
          <ul>
            {Object.entries(updateStageResult.error.errors ?? {}).map(
              ([k, v]) => (
                <li key={k}>{`${k} ${v.join(',')}`}</li>
              ),
            )}
          </ul>
        </Alert>
      )}
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.reviewQuestionnaireTitleText)}
      >
        <Typography className={classes.reviewHeader}>
          <FormattedMessage {...messages.reviewQuestionnaireHeaderHelperText} />
        </Typography>
        <FormBuilder />
      </StageSettingCard>
    </Grid>
  );
};
