import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';

import { initialAxAgentValues, steps } from '../constants';
import { AxAgentValues } from '../types';
import { ChooseBrand } from './ChooseBrand';
import { CustomizeChatbot } from './CustomizeChatbot';
import { Footer } from './Footer';
import { KnowledgeBase } from './KnowledgeBase';
import { useChatProgressorStyles } from './styles';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import useValidation from './useValidation';

export interface ChatbotProgressorProps {
  accessToken: string;
  accountSlug: string;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  axAgentValues: AxAgentValues;
  knowledgeBaseOption: string;
  setKnowledgeBaseOption: React.Dispatch<React.SetStateAction<string>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const ChatbotProgressor: VFC<ChatbotProgressorProps> = ({
  accessToken,
  accountSlug,
  setStartChatbotProgressor,
  setAxAgentValues,
  axAgentValues,
  knowledgeBaseOption,
  setKnowledgeBaseOption,
  currentStep,
  setCurrentStep,
}) => {
  const classes = useChatProgressorStyles();
  const history = useHistory();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { errors, validateFields, updateErrors } = useValidation();

  const resetAgent = () => {
    setStartChatbotProgressor(false);
    setAxAgentValues(initialAxAgentValues);
    setKnowledgeBaseOption('');
    setCurrentStep(1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setAxAgentValues(prev => ({
      ...prev,
      [name]: newValue,
    }));

    updateErrors(name, newValue, axAgentValues);
    setHasUnsavedChanges(true);
  };

  const handleDiscard = () => {
    setHasUnsavedChanges(false);
    setShowUnsavedChangesModal(false);
    if (nextUrl) {
      const unblock = history.block(() => undefined);
      history.push(nextUrl);
      unblock();
    } else {
      resetAgent();
    }
  };

  const handleCloseModal = () => {
    setShowUnsavedChangesModal(false);
    setNextUrl(null);
  };

  useEffect(() => {
    const unblock = history.block(location => {
      if (hasUnsavedChanges) {
        setNextUrl(location.pathname);
        setShowUnsavedChangesModal(true);
        return false; // Block navigation
      }
      return undefined; // Explicitly return undefined to allow navigation
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history]);

  const handleGoBackToAgentList = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesModal(true);
    } else {
      resetAgent();
    }
  };

  const renderStep = (): JSX.Element => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <KnowledgeBase
              setAxAgentValues={setAxAgentValues}
              accessToken={accessToken}
              axAgentValues={axAgentValues}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              knowledgeBaseOption={knowledgeBaseOption}
              setKnowledgeBaseOption={setKnowledgeBaseOption}
              accountSlug={accountSlug}
            />
            {!isLoading && axAgentValues.knowledgeBaseUuid && (
              <Footer
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setAxAgentValues={setAxAgentValues}
                axAgentValues={axAgentValues}
                accessToken={accessToken}
                accountSlug={accountSlug}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
            <CustomizeChatbot
              handleChange={handleChange}
              accessToken={accessToken}
              setAxAgentValues={setAxAgentValues}
              axAgentValues={axAgentValues}
              errors={errors}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              accountSlug={accountSlug}
            />
            {!isLoading && (
              <Footer
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setAxAgentValues={setAxAgentValues}
                axAgentValues={axAgentValues}
                accessToken={accessToken}
                validateFields={() => validateFields(axAgentValues)}
                accountSlug={accountSlug}
              />
            )}
          </>
        );
      case 3:
        return (
          <ChooseBrand
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            axAgentValues={axAgentValues}
            accessToken={accessToken}
            setAxAgentValues={setAxAgentValues}
            setStartChatbotProgressor={setStartChatbotProgressor}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            resetAgent={resetAgent}
            accountSlug={accountSlug}
          />
        );
      default:
        return <div>Step not found</div>;
    }
  };

  return (
    <div className={classes.chatbotProgressor}>
      <div className={classes.stepProgressBar}>
        {steps.map(step => (
          <div key={step.number} className={classes.stepContainer}>
            <div
              className={`${classes.step} ${
                step.number <= currentStep ? classes.active : classes.inactive
              }`}
            >
              {step.number < currentStep ? '✓' : step.number}
            </div>
            <Typography variant="caption">{step.label}</Typography>
          </div>
        ))}
      </div>
      {!isLoading && (
        <div className={classes.backToAgentList}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              handleGoBackToAgentList();
            }}
          >
            Back to Candidate AI Agent
          </Button>
        </div>
      )}
      {renderStep()}
      {showUnsavedChangesModal && (
        <UnsavedChangesModal
          handleClose={handleCloseModal}
          handleDiscard={handleDiscard}
          open
        />
      )}
    </div>
  );
};

export default ChatbotProgressor;
