import React, { useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FileUpload } from 'containers/AIWorkflowBuilder/ChatBot/MediaMessage/FileUpload';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { AxAgentValues } from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { createKnowledgeBase, uploadFaq } from '../../../api';
import { messages } from '../../../messages';
import { ResultStatus } from '../../../resultStatus';

export interface UploadProps {
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  accessToken: string;
  setIsKnowledgeBaseUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

type UploadState = ResultStatus<File, string>;

export const Upload: VFC<UploadProps> = ({
  setAxAgentValues,
  accessToken,
  setIsKnowledgeBaseUploaded,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { external_id: userExternalId } = useSelector(makeSelectWhoami());

  const [uploadState, setUploadState] = useState<UploadState>({
    status: 'idle',
    isLoading: false,
    isError: false,
  });

  const handleSubmit = async () => {
    if (uploadState.status !== 'ready') return;

    setUploadState({ status: 'loading', isLoading: true, isError: false });

    try {
      const createdKnowledgeBase = await createKnowledgeBase(accessToken, 'v1');
      await uploadFaq(
        accessToken,
        createdKnowledgeBase.uuid,
        uploadState.data,
        userExternalId,
      );

      setAxAgentValues(prev => ({
        ...prev,
        knowledgeBaseUuid: createdKnowledgeBase.uuid,
      }));

      setUploadState({
        status: 'success',
        data: uploadState.data,
        isLoading: false,
        isError: false,
      });
      setIsKnowledgeBaseUploaded(true);
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.uploadFileSuccess),
          'success',
        ),
      );
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.uploadFileFailed),
          'error',
        ),
      );
      setUploadState({
        status: 'error',
        error: 'Upload failed',
        isLoading: false,
        isError: true,
      });
    }
  };

  const handleUpload = (file: File) => {
    setUploadState({
      status: 'ready',
      data: file,
      isLoading: false,
      isError: false,
    });
  };

  return (
    <>
      <FileUpload
        handleUpload={handleUpload}
        handleSubmit={handleSubmit}
        isUploading={uploadState.isLoading}
        isUploadSuccess={uploadState.status === 'success'}
        isUploadFailed={uploadState.status === 'error'}
        uploadedFileData={undefined}
        validType="text/csv"
      />
    </>
  );
};
