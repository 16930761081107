import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IPhoneFrame } from '../ChatbotSetup/IPhoneFrame';
import { messages } from './messages';
import { useStyles } from './styles';

export const Preview = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.previewContainer}>
      <Typography variant="h2" className={classes.tryOutTitle}>
        <FormattedMessage {...messages.tryItOut} />
      </Typography>
      <IPhoneFrame src="https://fai-widget.fountain.com/ax-agent/286db791-1890-4f64-97a1-6017cb21771e/apply/chat?show=true" />
    </Grid>
  );
};
