import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { BackToCompanySettings } from 'components/BackToCompanySettings';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import BoostApplicantEngagement from './images/BoostApplicantEngagement.svg';
import CandidateAIAgent from './images/CandidateAIAgent.svg';
import MaximizeRecruiterProductivity from './images/MaximizeRecruiterProductivity.svg';
import RoundTheClockAssistance from './images/RoundTheClockAssistance.svg';
import { messages } from './messages';
import { useStyles } from './styles';

export const Content = () => {
  const classes = useStyles();
  const whoami = useSelector(makeSelectWhoami());

  const handleRequestDemoButtonClick = () => {
    const [firstName, lastName] = whoami.name.split(' ');
    const url = `https://www.fountain.com/upgrade-ai?first=${encodeURIComponent(
      firstName,
    )}&last=${encodeURIComponent(lastName)}&company=${encodeURIComponent(
      whoami.account_name_v2,
    )}&email=${encodeURIComponent(whoami.email)}`;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Grid className={classes.contentContainer}>
      <Grid className={classes.back}>
        <BackToCompanySettings />
      </Grid>
      <Grid className={classes.headerSection}>
        <Grid className={classes.headerTitle}>
          <Avatar src={CandidateAIAgent} />
          <Typography variant="h1">
            <FormattedMessage {...messages.candidateAiAgent} />
          </Typography>
        </Grid>
        <Grid className={classes.headerDescription}>
          <Typography variant="body2" className={classes.description}>
            <FormattedMessage
              {...messages.candidateAiAgentDesc}
              values={{
                helpCenterLink: (chunks: React.ReactNode) => (
                  <a href=" https://support.fountain.com/hc/en-us/articles/28599504350619-How-to-Use-Candidate-AI-Agent">
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRequestDemoButtonClick}
        >
          <FormattedMessage {...messages.requestDemo} />
        </Button>
      </Grid>

      <Grid className={classes.cardSection}>
        <Card className={classes.card}>
          <CardContent className={classes.cardHeader}>
            <Grid className={classes.cardTitle}>
              <Avatar src={MaximizeRecruiterProductivity} />
              <Typography variant="h3">
                <FormattedMessage {...messages.maximizeRecruiterProductivity} />
              </Typography>
            </Grid>
            <Typography variant="body1" className={classes.cardDescription}>
              <FormattedMessage
                {...messages.maximizeRecruiterProductivityDesc}
              />
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardHeader}>
            <Grid className={classes.cardTitle}>
              <Avatar src={BoostApplicantEngagement} />
              <Typography variant="h3">
                <FormattedMessage {...messages.boostApplicantEngagement} />
              </Typography>
            </Grid>
            <Typography variant="body1" className={classes.cardDescription}>
              <FormattedMessage {...messages.boostApplicantEngagementDesc} />
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardHeader}>
            <Grid className={classes.cardTitle}>
              <Avatar src={RoundTheClockAssistance} />
              <Typography variant="h3">
                <FormattedMessage {...messages.roundTheClockAssistance} />
              </Typography>
            </Grid>
            <Typography variant="body1" className={classes.cardDescription}>
              <FormattedMessage {...messages.roundTheClockAssistanceDesc} />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
