import { createContext } from '@fountain/fountain-ui-components';

import { StageDetailPatchResponse } from 'containers/WorkflowEditor/contexts/stageContext';

type UpdateStageResultContext = StageDetailPatchResponse | null;

export const [
  UpdateStageResultContextProvider,
  useUpdateStageResultContextState,
] = createContext<UpdateStageResultContext>({
  defaultValue: null,
});
