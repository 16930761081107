import { Grid, TextField, Typography } from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './messages';
import { useStyles } from './styles';

export interface EventDetailsProps {
  handleChange: (params: Partial<EventAvailableSlot>) => void;
  availableSlot: EventAvailableSlot | undefined;
}

export const EventDetails: VFC<EventDetailsProps> = ({
  handleChange,
  availableSlot,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const name = 'event-name';
  const instruction = 'event-instruction';

  return (
    <Grid
      container
      className={styles.rowContainer}
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h3">
              <FormattedMessage {...messages.eventDetails} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage {...messages.eventNameInstruction} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="body2" className={styles.label}>
          <label htmlFor={name}>
            <FormattedMessage {...messages.eventName} />{' '}
          </label>
        </Typography>

        <TextField
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange({ title: event.target.value })
          }
          placeholder={intl.formatMessage(messages.eventPlaceholder)}
          fullWidth
          variant="outlined"
          size="small"
          value={availableSlot?.title}
          id={name}
        />
      </Grid>
      <Grid item>
        <Typography variant="body2" className={styles.label}>
          <label htmlFor={instruction}>
            <FormattedMessage {...messages.eventInstructions} />{' '}
          </label>
        </Typography>
        <TextField
          fullWidth
          placeholder={intl.formatMessage(messages.instructionsPlaceholder)}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange({ instructions: event.target.value })
          }
          variant="outlined"
          size="medium"
          multiline
          rows={3}
          value={availableSlot?.instructions}
          id={instruction}
        />
      </Grid>
    </Grid>
  );
};
