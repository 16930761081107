import { StyledReactSelect } from '@fountain/fountain-ui-components';
import {
  Stage,
  WorkflowPartnerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { useWorkflowWithStages } from 'components/ChangeWorkflowModal/useWorkflowWithStages';
import { SettingSwitch } from 'containers/WorkflowEditor/components/WorkflowEditorContent/SettingSwitch';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { DELAY_SENDING_WEBHOOK } from '../constants';
import { useStyles } from '../styles';
import { messages } from './messages';

interface DelaySendingWebhookSettingsProps {
  stage: WorkflowPartnerStage;
  workflowId: string;
}

export const DelaySendingWebhookSettings: VFC<
  DelaySendingWebhookSettingsProps
> = ({ stage, workflowId }) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage, updateStageResult } = useContext(StageContext);
  const { additional_info: additionalInfo } = stage;

  const webhookDelaySettings = additionalInfo.webhook_delay_settings;
  const delaySendingWebhook = webhookDelaySettings?.delay_sending_webhook;
  const delayUntilStageId = webhookDelaySettings?.delay_until_stage_id;

  const { result: sourceStagesResult } = useWorkflowWithStages(workflowId);

  const onTargetStageChange = (option?: Stage) => {
    setStage(
      produce(stage, draftStage => {
        if (draftStage.type === 'PartnerStage') {
          if (draftStage.additional_info.webhook_delay_settings) {
            draftStage.additional_info.webhook_delay_settings.delay_until_stage_id =
              option?.id;
          }
        }
      }),
    );
  };

  const onToggleDelaySendingWebhook = () => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.type === 'PartnerStage') {
          if (draftStage.additional_info.webhook_delay_settings) {
            const newValue =
              !draftStage.additional_info.webhook_delay_settings
                .delay_sending_webhook;

            draftStage.additional_info.webhook_delay_settings.delay_sending_webhook =
              newValue;
          }
        }
      }),
    );
  };

  const allowedStages =
    sourceStagesResult.status === 'ready'
      ? sourceStagesResult.data.stages.filter(stage => !stage.parent_stage_id)
      : [];

  const selectedStage =
    sourceStagesResult.status === 'ready'
      ? allowedStages.find(stage => stage.id === String(delayUntilStageId))
      : null;

  const getAvailableStages = (currentStagePosition: number) => {
    if (sourceStagesResult.status !== 'ready') return [];

    return allowedStages
      .filter((s: Stage) => parseInt(s.position, 10) > currentStagePosition)
      .sort(
        (a: Stage, b: Stage) =>
          parseInt(a.position, 10) - parseInt(b.position, 10),
      );
  };

  // Get stages in ascending order by position, excluding those with lower positions than the current stage.
  // If sourceStagesResult is not 'ready', return an empty array.
  const availableStages = getAvailableStages(stage.position);

  // Assign `selectedStage` if present, or fall back to the first available stage.
  const selectedValue = selectedStage ?? availableStages[0];

  // Set the selected target stage if the delay sending webhook is enabled AND if a valid target stage is selected.
  if (delaySendingWebhook && selectedValue) onTargetStageChange(selectedValue);

  return (
    <>
      <SettingSwitch
        dataKey={DELAY_SENDING_WEBHOOK}
        checked={!!delaySendingWebhook}
        onChange={onToggleDelaySendingWebhook}
        label={intl.formatMessage(messages.delaySendingWebook, {
          partnerName: stage.title,
        })}
      />

      {sourceStagesResult.status === 'ready' && (
        <StyledReactSelect
          aria-label={intl.formatMessage(messages.targetStage)}
          className={styles.selectOptionDropdownForToggle}
          getOptionLabel={(option: Stage) => option.title}
          getOptionValue={(option: Stage) => option.id}
          label={intl.formatMessage(messages.targetStage)}
          onChange={onTargetStageChange}
          options={availableStages}
          value={selectedValue}
          isDisabled={!delaySendingWebhook}
          error={
            updateStageResult.isError &&
            updateStageResult.error?.errors?.partner_stage?.[0]
          }
        />
      )}
    </>
  );
};
