import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.containers.ChatAgentUpsell.title',
    defaultMessage: 'Fountain AI Upsell',
  },
  candidateAiAgent: {
    id: 'app.containers.ChatAgentUpsell.candidateAiAgent',
    defaultMessage: 'Candidate AI Agent',
  },
  candidateAiAgentDesc: {
    id: 'app.containers.ChatAgentUpsell.candidateAiAgentDesc',
    defaultMessage: `Elevate your hiring game with Candidate AI Agent in Fountain Hire. Automate routine tasks, engage candidates instantly, and let AI handle the details, so your team can focus on hiring top talent faster. <helpCenterLink>Learn more</helpCenterLink>`,
  },
  maximizeRecruiterProductivity: {
    id: 'app.containers.ChatAgentUpsell.maximizeRecruiterProductivity',
    defaultMessage: 'Maximize Recruiter Productivity',
  },
  maximizeRecruiterProductivityDesc: {
    id: 'app.containers.ChatAgentUpsell.maximizeRecruiterProductivityDesc',
    defaultMessage:
      'Free your team from repetitive tasks. Candidate AI Agent automatically answers FAQs, schedules interviews, and guides candidates through the application process, giving your recruiters more time to focus on high-impact hiring.',
  },
  boostApplicantEngagement: {
    id: 'app.containers.ChatAgentUpsell.boostApplicantEngagement',
    defaultMessage: 'Boost Applicant Engagement',
  },
  boostApplicantEngagementDesc: {
    id: 'app.containers.ChatAgentUpsell.boostApplicantEngagementDesc',
    defaultMessage:
      'Keep candidates engaged with instant, 24/7 support. Whether through SMS, web chat, or WhatsApp, Candidate AI Agent ensures no candidate is left waiting, reducing drop-offs and accelerating the path to hire.',
  },
  roundTheClockAssistance: {
    id: 'app.containers.ChatAgentUpsell.roundTheClockAssistance',
    defaultMessage: 'Round-the-Clock Assistance',
  },
  roundTheClockAssistanceDesc: {
    id: 'app.containers.ChatAgentUpsell.roundTheClockAssistanceDesc',
    defaultMessage:
      'Think of it as your 24/7 hiring partner. It’s always on, ready to answer questions and keep the process moving seamlessly—even when your team is off the clock.',
  },
  requestDemo: {
    id: 'app.containers.ChatAgentUpsell.requestDemo',
    defaultMessage: 'Request Demo',
  },
  tryItOut: {
    id: 'app.containers.ChatAgentUpsell.tryItOut',
    defaultMessage: 'Try it out today!',
  },
});
