import { EventAvailableSlot } from 'api-clients/monolith';
import { useIntl } from 'react-intl';

import { messages } from './messages';

export const defaultEventData = {
  title: '',
  instructions: '',
  start_time: '',
  end_time: '',
  location: '',
  max_attendees: '1',
  opening_stage_ids: [],
  booked_slots_count: undefined,
  period: '' as EventAvailableSlot['period'],
  user: '',
};

export const conditionTypes = [
  { value: 'stage', label: 'Stage is...' },
  { value: 'opening', label: 'Opening is...' },
  { value: 'location_group', label: 'Location Group is...' },
];

export const repeatEvent: { value: string; label: string }[] = [
  { value: '', label: 'Does not repeat' },
  { value: 'days', label: 'Daily' },
  { value: 'weeks', label: 'Weekly' },
  { value: 'weekdays', label: 'Mon - Fri' },
];

export const useFrequencyOptions = () => {
  const intl = useIntl();

  return Array.from({ length: 30 }, (_, index) => {
    const count = index + 1;
    return {
      value: count,
      label: intl.formatMessage(messages.frequencyOptions, { count }),
    };
  });
};

export type EventKey = keyof EventAvailableSlot;
export type EventValue = EventAvailableSlot[EventKey];

export type HandleSettingChange = (key: EventKey, value: EventValue) => void;

export const NO_OPTIONS = 15;
