import React from 'react';

import { SharedConditionProps } from '../../../types';
import { ComparatorSelect } from '../ComparatorSelect';
import { CustomerAttributeTypeSelect } from './CustomerAttributeTypeSelect';
import { CustomerAttributeValueSelect } from './CustomerAttributeValueSelect';

export const CustomerAttributesSelect: React.VFC<
  SharedConditionProps
> = props => {
  return (
    <>
      <CustomerAttributeTypeSelect {...props} />
      <ComparatorSelect onlyReturnBasicComparators {...props} />
      <CustomerAttributeValueSelect {...props} />
    </>
  );
};
