import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { StageSettingCard } from 'containers/WorkflowEditor/components';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { IsHiddenFromPortal } from '../SettingsSwitches';
import { advancedSettingsMessages } from './messages';

const { advancedSettingsCardTitleText } = advancedSettingsMessages;

export const AdvancedSettings = () => {
  const intl = useIntl();
  const { setStage, stage } = useContext(StageContext);

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(advancedSettingsCardTitleText)}
    >
      <IsHiddenFromPortal stage={stage} setStage={setStage} />
    </StageSettingCard>
  );
};
