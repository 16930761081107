import { StyledReactSelect, theme } from '@fountain/fountain-ui-components';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { EventAvailableSlot, UsersService } from 'api-clients/monolith';
import { EventUserOption, useUsersOptions } from 'Calendar/hooks';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { useApiService } from 'hooks/useApiService';
import InfoIcon from 'images/InfoIcon';

import { messages } from '../messages';

const useStyles = makeStyles(theme =>
  createStyles({
    rowContainer: {
      marginTop: theme.spacing(3),
    },
    rowChild: {
      marginTop: theme.spacing(2),
    },
    columnContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    gapBetween: {
      gap: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    required: {
      '&::after': {
        content: "'*'",
        color: theme.palette.common.red400,
        margin: theme.spacing(0.5),
      },
    },
    tooltipIcon: {
      margin: theme.spacing(0, 0, 0, 1),
    },
    nestedRequired: {
      '& p': {
        '&::after': {
          content: "'*'",
          color: theme.palette.common.red400,
          margin: theme.spacing(0.5),
        },
      },
    },
  }),
);

export interface EventCapacityProps {
  handleChange: (params: Partial<EventAvailableSlot>) => void;
  availableSlot: EventAvailableSlot;
  errors: Partial<Record<keyof EventAvailableSlot, string>>;
}

export const EventCapacity: VFC<EventCapacityProps> = ({
  handleChange,
  availableSlot,
  errors,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const userId = urlSearchParams.get('userId');

  const getUserInformation = useCallback(() => {
    return UsersService.getInternalApiUsersPack(Number(userId));
  }, [userId]);

  const [filteredUserId, setFilteredUserId] = useState<string | undefined>();

  const { result: userInformationResult } = useApiService(getUserInformation);

  useEffect(() => {
    if (!userId) {
      return;
    }
    if (userInformationResult.status === 'ready') {
      setFilteredUserId(userInformationResult.data.external_id);
    } else {
      setFilteredUserId(undefined);
    }
  }, [userId, userInformationResult]);

  const [userOptions, setUserOptions] = useState<EventUserOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<{
    // eslint-disable-next-line camelcase
    external_id: string;
    name: string;
  }>();
  const { result: userOptionsResult } = useUsersOptions();

  const { external_id: externalId } = useSelector(makeSelectWhoami());

  useEffect(() => {
    if (userOptionsResult.status === 'ready') {
      setUserOptions(userOptionsResult.data);

      const selectedUser = userOptionsResult.data.find(
        user =>
          user.external_id ===
          (availableSlot.user_external_id ?? filteredUserId ?? externalId),
      );
      setSelectedUser(selectedUser);
      handleChange({ user_external_id: selectedUser?.external_id });
    }
  }, [
    userOptionsResult,
    availableSlot.user_external_id,
    filteredUserId,
    externalId,
    handleChange,
  ]);

  return (
    <Box>
      <Grid container xs={12} className={styles.rowContainer} direction="row">
        <Grid item xs={12}>
          <Typography variant="h3">
            <FormattedMessage {...messages.eventCapacity} />
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.description}
          >
            <FormattedMessage {...messages.eventCapacityDescription} />
          </Typography>
          <Grid className={styles.rowChild}>
            <Box display="flex" className={styles.gapBetween}>
              <Grid item xs={12} sm={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.host)}
                  aria-label={intl.formatMessage(messages.host)}
                  options={userOptions}
                  value={selectedUser}
                  getOptionLabel={(option: EventUserOption) => option.name}
                  getOptionValue={(option: EventUserOption) =>
                    option.external_id
                  }
                  isLoading={userOptionsResult.isLoading}
                  className={styles.nestedRequired}
                  // eslint-disable-next-line camelcase
                  onChange={(option: { name: string; external_id: string }) =>
                    handleChange({ user_external_id: option.external_id })
                  }
                  error={Boolean(errors?.user_external_id)}
                  data-testid="event-host"
                />
                {errors?.user_external_id && (
                  <Error error={errors.user_external_id} />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Tooltip
                  title={intl.formatMessage(messages.maxAttendeesTooltip)}
                >
                  <Typography
                    variant="body2"
                    className={classNames(styles.label, styles.required)}
                  >
                    <FormattedMessage {...messages.maxAttendees} />
                    <InfoIcon
                      color={theme.palette.common.gray800}
                      className={styles.tooltipIcon}
                    />
                  </Typography>
                </Tooltip>
                <TextField
                  fullWidth
                  type="number"
                  onChange={event =>
                    handleChange({ max_attendees: event?.target?.value })
                  }
                  InputProps={{ inputProps: { min: 1 } }}
                  variant="outlined"
                  size="small"
                  value={availableSlot.max_attendees}
                  error={Boolean(errors?.max_attendees)}
                />
                {errors?.max_attendees && (
                  <Error error={errors.max_attendees} />
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
