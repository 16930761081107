export interface Filterable extends Record<string, string> {
  title: string;
  id: string;
  name: 'stage' | 'opening' | 'locationGroup';
}

type FilterableName = Filterable['name'];

export const mapFilterable = <T extends string | Record<string, unknown>>(
  items: T[] | undefined,
  options: {
    name: FilterableName;
    titleAttr?: string;
    idAttr?: string;
  },
): Filterable[] => {
  if (!items?.length) return [];

  return items.map(item => {
    if (typeof item === 'string') {
      return { title: item, id: item, name: options.name };
    }

    if (!options.titleAttr || !options.idAttr) {
      throw new Error(
        'titleAttr and idAttr are required for non-string arrays',
      );
    }

    return {
      title: String(item[options.titleAttr]),
      id: String(item[options.idAttr]),
      name: options.name,
    };
  });
};
