import { Button, Grid, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from 'containers/ChatAgent/messages';

import { useSetupInstructionsStyles } from './styles';

interface SetupInstructionsProps {
  onStartChatbotProgressor: () => void;
}

export const SetupInstructions: VFC<SetupInstructionsProps> = ({
  onStartChatbotProgressor,
}) => {
  const classes = useSetupInstructionsStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.header} />
        </Typography>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.section}>
          <Grid>
            <Typography variant="h2">
              <FormattedMessage {...messages.setupFirstWidget} />
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">
              <FormattedMessage {...messages.setupDescription} />
            </Typography>
          </Grid>
          <Grid className={classes.list}>
            <Grid>
              1. <b>Upload a knowledge base</b> to train the chatbot how to
              respond to applicant questions.
            </Grid>
            <Grid>
              2. <b>Customize AI Agent</b> to personalize its name, interactions
              with and visibility to applicants.
            </Grid>
            <Grid>
              3. <b>Choose brands</b> to enable the chatbot and match its
              appearance, such as color and logo.
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.footer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onStartChatbotProgressor}
          >
            <FormattedMessage {...messages.setupAiAgent} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
