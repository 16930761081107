import { WorkflowAssessmentStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { AdvancedSettings } from 'containers/WorkflowEditor/components/AdvancedSettings';
import { AssessmentRules } from 'containers/WorkflowEditor/components/Rules/AssessmentRules';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { AssessmentCard } from './AssessmentCard';

export interface AssessmentStageProps {
  stage: WorkflowAssessmentStage;
}

export const AssessmentStage: VFC<AssessmentStageProps> = ({ stage }) => {
  const isSubstage = Boolean(stage.parent_stage_id);

  return (
    <div data-testid="assessmentStage">
      <StageSection>
        <AssessmentCard stage={stage} />
      </StageSection>
      <StageSection>
        <AssessmentRules stage={stage} />
      </StageSection>
      {!isSubstage && (
        <>
          <StageSection>
            <IdleMoveRule stage={stage} />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="assessment"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          <StageSection>
            <StageSettingMessageCard
              messageType="trigger"
              stageExternalId={stage.external_id}
            />
          </StageSection>
          {stage.account_go_enabled && (
            <StageSection>
              <AdvancedSettings stage={stage} />
            </StageSection>
          )}
        </>
      )}
    </div>
  );
};
