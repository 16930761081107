/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataFields } from './DataFields';
import type { WorkflowBaseStage } from './WorkflowBaseStage';

export enum ReviewStageDataFieldTypeEnum {
  textField = 'text_field',
  radioField = 'radio',
  datePickerField = 'datepicker'
}

// unfortunately the types at the monolith level arent a discriminated union based off the type property
// which causes differentials further down where we create, edit, etc i need to customize the type a bit below
// also since the stage workflow uses top level state from the api as the source of truth that we manipulate
// we need to add some additional changes (like _destroy, id is optional (new questions wont have ids yet)), etc
type MonolithDataField = DataFields[number];
export type ReviewStageDataField = Omit<MonolithDataField, 'extra' | 'id' | 'predefined' | 'options' | 'type'> & {
  id?: number;
  type: ReviewStageDataFieldTypeEnum;
  _destroy?: boolean;
  position: number;
  options: Array<{
    _id: string;
    value: string;
    label: string;
    visible: boolean;
  }>
}
export type WorkflowReviewStage = WorkflowBaseStage & {
  type: 'ReviewStage';
  additional_info: {
    hiring_manager_instructions: string | null;
    review_questionnaire: Array<ReviewStageDataField>
  };
};
