import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  review: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.review`,
    defaultMessage: 'Review',
  },
  reviewHeader: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.reviewHeader`,
    defaultMessage:
      "Configure what you'd like your frontline workforce to see when a candidate shows up for review.",
  },
  hiringManagerInstructionsHeader: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsHeader`,
    defaultMessage: 'Hiring manager instructions',
  },
  optional: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.optional`,
    defaultMessage: '(optional)',
  },
  hiringManagerInstructionsText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsText`,
    defaultMessage:
      'Provided instructions to a Hire Go user to prepare them for review candidates',
  },
  hiringManagerInstructionsPlaceHolder: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsPlaceHolder`,
    defaultMessage: 'Provide instructions to a Hire Go user',
  },
  reviewQuestionnaireHeaderHelperText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.reviewQuestionnaire.reviewQuestionnaireHeaderHelperText`,
    defaultMessage:
      'Set up a questionnaire for hiring managers as part of the review process',
  },
  reviewQuestionnaireTitleText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.reviewQuestionnaire.reviewQuestionnaireTitleText`,
    defaultMessage: 'Review questionnaire',
  },
});

export const advancedSettingsMessages = defineMessages({
  advancedSettingsCardTitleText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.AdvancedSettings.advancedSettingsCardTitleText`,
    defaultMessage: 'Advanced Settings',
  },
});
